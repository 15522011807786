import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

import Page from "../components/Page/Page";
import AppsGrid from "../components/AppsGrid/AppsGrid";

import { getApps } from "../loaders/apps";

export default function Apps() {
  const { t } = useTranslation(["dashboard"]);

  const { data: apps, isLoading } = useQuery(["apps"], getApps);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Page>
        <Page.Header>{t("apps.title")}</Page.Header>
        <Page.Section>
          <AppsGrid apps={apps} />
        </Page.Section>
      </Page>
    </>
  );
}
