import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Button from "../core/Button/Button";

function TabButton({ name, selected, onChange }) {
  const { t } = useTranslation(["dashboard"]);

  const variant = name === selected ? "primary" : "neutral";

  return (
    <Button className="ml-2" variant={variant} onClick={() => onChange(name)}>
      {t(`timelinesDrilldown.tabs.${name}`)}
    </Button>
  );
}

TabButton.propTypes = {
  name: PropTypes.string.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default function TimelinesTabSelector({ selected, onChange }) {
  return (
    <div>
      <div className="grid gap-x-4 sm:grid-cols-12">
        <TabButton
          name="linesChart"
          selected={selected}
          onChange={onChange}
        ></TabButton>
        <TabButton
          name="table"
          selected={selected}
          onChange={onChange}
        ></TabButton>
      </div>
    </div>
  );
}

TimelinesTabSelector.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
