import PropTypes from "prop-types";
import _ from "lodash";

export const EmptyTooltip = ({ active }) => {
  if (active) {
    return <></>;
  }

  return null;
};

EmptyTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
};

export const ChildCategoriesTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const orderedPayload = _.orderBy(payload, "name");

    return (
      <div
        style={{
          backgroundColor: "var(--tw-color-white)",
          border: "2px solid var(--tw-color-black)",
        }}
      >
        {orderedPayload.map(({ name, color, payload: { y } }) => {
          return <div style={{ color }} key={name}>{`${name}: ${y}`}</div>;
        })}
      </div>
    );
  }

  return null;
};

ChildCategoriesTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.array,
};
