import { Fragment } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { Menu, Transition } from "@headlessui/react";

import { Bars3Icon } from "@heroicons/react/24/outline";

const MenuItem = (props) => (
  <Menu.Item>
    {({ active }) => (
      <a
        className={classnames("block px-4 py-2 text-sm text-gray-700", {
          "bg-gray-100": active,
        })}
        {...props}
      />
    )}
  </Menu.Item>
);

const ProfileMenu = () => {
  const { t } = useTranslation();

  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="group flex items-center rounded-full text-sm focus:outline-none">
          <Bars3Icon className="mr-3 h-10 w-10 flex-shrink-0 text-sm text-ps-600" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <MenuItem href="/logout">{t("navbar.menu.logout")}</MenuItem>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileMenu;
