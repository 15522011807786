import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@tanstack/react-query";

import Button from "../components/core/Button/Button";
import Spinner from "../components/Spinner";
import Page from "../components/Page/Page";
import UploadData from "../components/UploadData";
import Grid from "../components/InternalBenchmarks/Grid";
import BuildingSelector from "../components/BuildingSelector/BuildingSelector";

import {
  getInternalBenchmarks,
  bulkDeleteInternalBenchmarks,
  uploadInternalBenchmarksFile,
} from "../loaders/internalBenchmarks";

export default function InternalBenchmarks() {
  const { t } = useTranslation(["dashboard"]);

  const gridRef = useRef(null);

  const [building, setBuilding] = useState(null);

  const {
    data: internalBenchmarks,
    isLoading,
    refetch,
  } = useQuery({
    enabled: !!building,
    queryKey: ["internal_benchmarks", building?.buildingId],
    queryFn: () => getInternalBenchmarks(building.buildingId),
  });

  const uploadMutation = useMutation({
    mutationFn: ({ file }) =>
      uploadInternalBenchmarksFile(building.buildingId, file),
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells();
      });
    },
  });

  const onUpload = useCallback((event) => {
    const files = Array.from(event.target.files);

    uploadMutation.mutate({
      file: files[0],
    });
  });

  const onDeleteInternalBenchmarks = useCallback(() => {
    const selection = gridRef.current.api.getSelectedRows();

    bulkDeleteMutation.mutate(
      selection.map((item) => item.internalBenchmarkId)
    );
  }, []);

  const bulkDeleteMutation = useMutation({
    mutationFn: bulkDeleteInternalBenchmarks,
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells();
      });
    },
  });

  return (
    <>
      <Page.Header>{t("internalBenchmarks.title")}</Page.Header>
      <Page.Section id="buildingSelector">
        <Page.Section.Content className="ag-theme-alpine">
          <div key="building">
            <BuildingSelector
              selected={building}
              onChange={setBuilding}
              allowMissing={false}
            />
          </div>
        </Page.Section.Content>
      </Page.Section>

      <Page.Section id="internalBenchmarks">
        <Page.Section.Content>
          <Button
            variant="negative"
            onClick={onDeleteInternalBenchmarks}
            className="ml-2"
          >
            {t("internalBenchmarks.deleteSelection")}
          </Button>
          <UploadData onUpload={onUpload} />
        </Page.Section.Content>
        {isLoading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <Grid internalBenchmarks={internalBenchmarks} ref={gridRef} />
        )}
      </Page.Section>
    </>
  );
}
