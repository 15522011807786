import { forwardRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@tanstack/react-query";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import useResizeObserver from "use-resize-observer";

import Page from "../Page/Page";
import AgGridReact from "../Grid/Grid";

import { updateBuilding } from "../../loaders/buildings";

import {
  BuildingReference,
  Units,
  Size,
  PrimaryUsage,
  Floors,
  ConstructionYear,
  Display,
  Portfolio,
} from "./columns/";

const Grid = forwardRef(({ rowData }, gridRef) => {
  const mutation = useMutation({
    mutationFn: updateBuilding,
  });

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        });
      }
    },
  });

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      });
    }
  }, []);

  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        filter: true,
        editable: true,
      },
      columnDefs: [
        BuildingReference,
        Units,
        Size,
        PrimaryUsage,
        Floors,
        ConstructionYear,
        Display,
        Portfolio,
      ],
      onCellValueChanged: ({ data }) => {
        const {
          portfolio: { name },
        } = data;

        data.portfolioName = name;

        mutation.mutate(data);
      },
      rowSelection: "multiple",
    }),
    [mutation]
  );

  return (
    <Page.Section.Content className="ag-theme-alpine">
      <AgGridReact
        domLayout="autoHeight"
        gridOptions={gridOptions}
        modules={[ClientSideRowModelModule, RichSelectModule]}
        onFirstDataRendered={onFirstDataRendered}
        ref={gridRef}
        rowClass="c-grid-row"
        rowData={rowData}
      />
    </Page.Section.Content>
  );
});

Grid.propTypes = {
  rowData: PropTypes.array,
};

Grid.displayName = "Grid";

export default Grid;
