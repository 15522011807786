import PropTypes from "prop-types";
import { useCallback, useReducer, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

import { getResultColor } from "../utils/color/result";

import {
  getCategoryBuildingDistribution,
  getBuildingCategoryDistribution,
} from "../loaders/result";
import { getBuildingCategoryNoiDefinitionRecords } from "../loaders/records";

import Button from "../components/core/Button/Button";
import AgGridReact from "../components/Grid/Grid";
import NoiDefinitionSelector from "../components/NoiDefinitionSelector/NoiDefinitionSelector";
import PortfolioSelector from "../components/PortfolioSelector/PortfolioSelector";
import CategorySelector from "../components/CategorySelector/CategorySelector";
import TimeFilterSelector from "../components/TimeFilterSelector/TimeFilterSelector";
import Page from "../components/Page/Page";
import { Tooltip } from "../components/Tooltip/Tooltip";
import BarChart from "../components/BarChart/BarChart";
import Spinner from "../components/Spinner";
import {
  AccountNumber,
  ProcessingDate,
  AccountName,
  RecordId,
  Amount,
  Text,
} from "../components/Records/columns";

const CategoryTooltip = ({ active, payload }) => {
  const { t } = useTranslation(["dashboard"]);

  if (active && payload && payload.length) {
    const { buildingReference, category, actual, budget, underwriting } =
      payload[0].payload;

    return (
      <Tooltip>
        <div className="custom-tooltip">
          <p className="label">{`${buildingReference}`}</p>
          <p className="label">{`${category.paddedName}`}</p>
          <p className="desc">
            {t("recordsDrilldown.categoryTooltip.actual", {
              actual: actual.toString(),
            })}
          </p>
          {budget && (
            <p className="desc">
              {t("recordsDrilldown.categoryTooltip.budget", {
                budget: budget.toString(),
              })}
            </p>
          )}
          {underwriting && (
            <p className="desc">
              {t("recordsDrilldown.categoryTooltip.underwriting", {
                underwriting: budget.toString(),
              })}
            </p>
          )}
        </div>
      </Tooltip>
    );
  }

  return null;
};

CategoryTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
};

const initialState = {
  portfolio: null,
  building: null,
  category: null,
  noiDefinition: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PORTFOLIO": {
      if (state.portfolio === action.data) {
        return state;
      }

      return {
        ...state,
        portfolio: action.data,
        building: null,
        buildingCategory: null,
      };
    }

    case "SET_NOI_DEFINITION": {
      if (state.noiDefinition === action.data) {
        return state;
      }

      return {
        ...state,
        noiDefinition: action.data,
        building: null,
        buildingCategory: null,
      };
    }

    case "SET_TIMEFILTER": {
      if (state.timeFilter === action.data) {
        return state;
      }

      return {
        ...state,
        timeFilter: action.data,
        building: null,
        buildingCategory: null,
      };
    }

    case "SET_CATEGORY": {
      if (state.category === action.data) {
        return state;
      }

      return {
        ...state,
        category: action.data,
        building: null,
        buildingCategory: null,
      };
    }

    case "SET_BUILDING": {
      const building = action.data;

      return { ...state, building };
    }
    case "SET_BUILDING_CATEGORY":
      return { ...state, buildingCategory: action.data };
    default:
      return state;
  }
};

const secondaryGetFill = () => {
  return `var(--tw-color-budget-1000)`;
};

export default function RecordsDrilldown() {
  const { t } = useTranslation(["dashboard", "translation"]);

  const gridRef = useRef();

  const [
    {
      portfolio,
      building,
      buildingReference,
      buildingCategory,
      category,
      noiDefinition,
      timeFilter,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const timeFilterName = timeFilter?.name;
  const portfolioId = portfolio?.portfolioId;
  const buildingId = building?.buildingId;
  const categoryId = category?.categoryId;
  const noiDefinitionId = noiDefinition?.noiDefinitionId;
  const buildingCategoryId = buildingCategory?.categoryId;

  const { data: categoryBuildingDistribution, isLoading } = useQuery({
    enabled: !!portfolioId && !!categoryId && !!noiDefinitionId,
    queryKey: [
      "categoryBuildingDistribution",
      categoryId,
      portfolioId,
      timeFilterName,
    ],
    queryFn: () =>
      getCategoryBuildingDistribution(
        categoryId,
        portfolioId,
        noiDefinitionId,
        timeFilter
      ),
    staleTime: Infinity,
  });

  const { data: buildingCategoryDistribution } = useQuery({
    enabled: !!buildingId && !!noiDefinitionId,
    queryKey: [
      "buildingCategoryDistribution",
      categoryId,
      buildingId,
      timeFilterName,
    ],
    queryFn: () =>
      getBuildingCategoryDistribution(buildingId, noiDefinitionId, timeFilter),
    staleTime: Infinity,
  });

  const { data: records } = useQuery({
    enabled: !!buildingCategoryId,
    queryKey: [
      "buildingCategoryRecords",
      buildingId,
      buildingCategoryId,
      noiDefinitionId,
      timeFilterName,
    ],
    queryFn: () =>
      getBuildingCategoryNoiDefinitionRecords(
        buildingId,
        buildingCategoryId,
        noiDefinitionId,
        timeFilter
      ),
    staleTime: Infinity,
  });

  const onChangeNoiDefinition = useCallback(
    (noiDefinition) =>
      dispatch({ type: "SET_NOI_DEFINITION", data: noiDefinition }),
    [dispatch]
  );

  const onChangePortfolio = useCallback(
    (portfolio) => dispatch({ type: "SET_PORTFOLIO", data: portfolio }),
    [dispatch]
  );

  const onChangeTimeFilter = useCallback(
    (timeFilter) => dispatch({ type: "SET_TIMEFILTER", data: timeFilter }),
    [dispatch]
  );

  const onChangeCategory = useCallback(
    (category) => dispatch({ type: "SET_CATEGORY", data: category }),
    [dispatch]
  );

  const onBuildingClick = useCallback(
    ({ activePayload }) =>
      dispatch({
        type: "SET_BUILDING",
        data: activePayload[0].payload,
      }),
    [dispatch]
  );

  const onBuildingCategoryClick = useCallback(
    ({ activePayload }) =>
      dispatch({
        type: "SET_BUILDING_CATEGORY",
        data: activePayload[0].payload.category,
      }),
    [dispatch]
  );

  const onDownload = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  });

  const gridOptions = {
    columnDefs: [
      RecordId,
      ProcessingDate,
      AccountName,
      AccountNumber,
      Text,
      Amount,
    ],
  };

  const isEmpty = !categoryBuildingDistribution?.distribution.length;

  return (
    <>
      <Page>
        <Page.Header>{t("recordsDrilldown.title")}</Page.Header>
        <Page.Section id="noiDefinition">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="noiDefinition" className="sm:col-span-4">
                <NoiDefinitionSelector
                  selected={noiDefinition}
                  onChange={onChangeNoiDefinition}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        <Page.Section id="portfolio">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="portfolio" className="sm:col-span-4">
                <PortfolioSelector
                  selected={portfolio}
                  onChange={onChangePortfolio}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        <Page.Section id="timeFilter">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="portfolio" className="sm:col-span-4">
                <TimeFilterSelector
                  selected={timeFilter}
                  onChange={onChangeTimeFilter}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        <Page.Section id="category">
          <Page.Section.Content>
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div key="category" className="sm:col-span-4">
                <CategorySelector
                  selected={category}
                  onChange={onChangeCategory}
                  topCategoryStandard="root__operating"
                  includeTopCategory={false}
                />
              </div>
            </div>
          </Page.Section.Content>
        </Page.Section>

        {isLoading ? (
          <Page.Section className="text-center justify-center">
            <Spinner isFull />
          </Page.Section>
        ) : isEmpty ? (
          <Page.Section>
            <div>{t("recordsDrilldown.empty")}</div>
          </Page.Section>
        ) : (
          <>
            <Page.Section id="categoryBarChart">
              <Page.Section.Title>
                {t("recordsDrilldown.categoryBarChart")}
              </Page.Section.Title>
              <Page.Section.Content>
                <BarChart
                  data={categoryBuildingDistribution.distribution}
                  BarChartToolTip={CategoryTooltip}
                  referenceLinesData={categoryBuildingDistribution.percentiles}
                  xAxisDataKey="buildingReference"
                  primaryDataKey="actual"
                  primaryGetFill={getResultColor}
                  secondaryDataKey="budget"
                  secondaryGetFill={secondaryGetFill}
                  onClick={onBuildingClick}
                ></BarChart>
              </Page.Section.Content>
            </Page.Section>

            {building && buildingCategoryDistribution && (
              <Page.Section id="buildingBarChart">
                <Page.Section.Title>
                  {buildingReference} - {t("recordsDrilldown.buildingBarChart")}
                </Page.Section.Title>
                <Page.Section.Content>
                  <BarChart
                    data={buildingCategoryDistribution}
                    BarChartToolTip={CategoryTooltip}
                    xAxisDataKey="category.paddedName"
                    primaryDataKey="actual"
                    primaryGetFill={getResultColor}
                    secondaryDataKey="budget"
                    secondaryGetFill={secondaryGetFill}
                    onClick={onBuildingCategoryClick}
                  ></BarChart>
                </Page.Section.Content>
              </Page.Section>
            )}
            {records && (
              <Page.Section id="buildingCategoryRecords">
                <Page.Section.Title>
                  {t("recordsDrilldown.buildingCategoryRecords")}{" "}
                </Page.Section.Title>
                <Page.Section.Content>
                  <Button onClick={onDownload} className="ml-2">
                    {t("recordsDrilldown.export")}
                  </Button>
                </Page.Section.Content>
                <Page.Section.Content className="ag-theme-alpine">
                  <AgGridReact
                    domLayout="autoHeight"
                    gridOptions={gridOptions}
                    ref={gridRef}
                    modules={[ClientSideRowModelModule, ExcelExportModule]}
                    rowClass="c-grid-row"
                    rowData={records}
                  />
                </Page.Section.Content>
              </Page.Section>
            )}
          </>
        )}
      </Page>
    </>
  );
}
