import { forwardRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import Page from "../Page/Page";
import AgGridReact from "../Grid/Grid";
import { getGridValueFormatter } from "../../utils/number";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

import { useTranslation } from "react-i18next";
import useResizeObserver from "use-resize-observer";

const ZeroProof = forwardRef(({ data }, gridRef) => {
  const { t } = useTranslation(["dashboard"]);

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        });
      }
    },
  });

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      });
    }
  }, []);

  const gridOptions = useMemo(
    () => ({
      columnDefs: [
        {
          headerName: "Building",
          width: 50,
          field: "buildingReference",
        },
        {
          headerName: "Year",
          width: 25,
          field: "year",
        },
        {
          headerName: "Operating income",
          width: 150,
          field: "operating_income",
          valueFormatter: getGridValueFormatter("operating_income"),
        },
        {
          headerName: "Operating expenses",
          width: 150,
          field: "operating_expense",
          valueFormatter: getGridValueFormatter("operating_expense"),
        },
        {
          headerName: "Financial income",
          width: 250,
          field: "financial_income",
          valueFormatter: getGridValueFormatter("financial_income"),
        },
        {
          headerName: "Financial expenses",
          width: 250,
          field: "financial_expense",
          valueFormatter: getGridValueFormatter("financial_expense"),
        },
        {
          headerName: "Result",
          width: 50,
          field: "result",
          valueFormatter: getGridValueFormatter("result"),
        },
        {
          headerName: "Assets change",
          width: 50,
          field: "asset",
          valueFormatter: getGridValueFormatter("asset"),
        },
        {
          headerName: "Liabilities change",
          width: 50,
          field: "liability",
          valueFormatter: getGridValueFormatter("liability"),
        },
        {
          headerName: "Net balance increase",
          width: 50,
          field: "netBalanceIncrease",
          valueFormatter: getGridValueFormatter("netBalanceIncrease"),
        },
        {
          headerName: "Sum check",
          width: 50,
          field: "sumCheck",
          valueFormatter: getGridValueFormatter("sumCheck"),
        },
      ],
    }),
    [data]
  );

  return (
    <>
      <Page.Section.Title>{t("zeroProof.title")}</Page.Section.Title>
      <Page.Section.Content className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          gridOptions={gridOptions}
          modules={[
            ClientSideRowModelModule,
            RichSelectModule,
            ExcelExportModule,
          ]}
          onFirstDataRendered={onFirstDataRendered}
          ref={gridRef}
          rowClass="c-grid-row"
          rowData={data}
        />
      </Page.Section.Content>
    </>
  );
});

ZeroProof.propTypes = {
  data: PropTypes.array.isRequired,
};

ZeroProof.displayName = "ZeroProof";

export default ZeroProof;
