import PropTypes from "prop-types";
import classnames from "classnames";

import { useTranslation } from "react-i18next";

import { getOverview } from "../../loaders/overview";
import { format } from "../../utils/number";
import { useQuery } from "@tanstack/react-query";

import Page from "../Page/Page";
import Card from "../Card/Card";
import Spinner from "../Spinner";

const stats = [
  { id: "actual", label: "overview.overview.stats.actual", diff: true },
  { id: "budget", label: "overview.overview.stats.budget" },
  { id: "underwriting", label: "overview.overview.stats.underwriting" },
];

const Diff = ({ value }) => {
  const isPositive = value > 0;

  if (!value) {
    return null;
  }

  const number = format(value, { signDisplay: "exceptZero" });

  return (
    <span
      className={classnames("text-base ml-1", {
        "text-green-500": isPositive,
        "text-red-500": !isPositive,
      })}
    >
      {number}
    </span>
  );
};

Diff.propTypes = {
  value: PropTypes.number.isRequired,
};

const Overview = ({
  portfolio,
  noiDefinition,
  timeFilter,
  absolute,
  excludeIncompleteMonths,
}) => {
  const { t } = useTranslation(["dashboard"]);

  const portfolioId = portfolio?.portfolioId;
  const noiDefinitionId = noiDefinition?.noiDefinitionId;

  const { data, isLoading, error } = useQuery({
    enabled: !!portfolio,
    queryKey: [
      "overview",
      portfolioId,
      noiDefinitionId,
      timeFilter,
      absolute,
      excludeIncompleteMonths,
    ],
    queryFn: () =>
      getOverview(
        portfolioId,
        noiDefinitionId,
        timeFilter,
        absolute,
        excludeIncompleteMonths
      ),
  });

  return (
    <>
      {isLoading || error ? (
        <Page.Section id="overview">
          <Page.Section.Content className="flex justify-center">
            <Spinner />
          </Page.Section.Content>
        </Page.Section>
      ) : (
        <Page.Section id="overview">
          <Page.Section.Title>
            {t("overview.overview.title")}
          </Page.Section.Title>
          <Page.Section.Content>
            <Card as="dl" className="grid grid-cols-3 gap-5 px-24 sm:px-24">
              {stats.map((item) => (
                <div key={item.id}>
                  <dt className="text-3xl font-medium">
                    {format(data[item.id])}
                    {item.diff && data.budgetDiff && (
                      <Diff value={data.budgetDiff} />
                    )}
                  </dt>
                  <dd className="text-base text-ps-500">{t(item.label)}</dd>
                </div>
              ))}
            </Card>
          </Page.Section.Content>
        </Page.Section>
      )}
    </>
  );
};

Overview.propTypes = {
  portfolio: PropTypes.object.isRequired,
  noiDefinition: PropTypes.object.isRequired,
  timeFilter: PropTypes.object.isRequired,
  absolute: PropTypes.bool.isRequired,
  excludeIncompleteMonths: PropTypes.bool.isRequired,
};

export default Overview;
