import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getAllPortfolios } from "../../loaders/portfolios";

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "../core/Listbox";

const usePortfolios = (options) => {
  const { data: portfolios, isLoading } = useQuery({
    queryKey: ["portfolios"],
    queryFn: getAllPortfolios,
    staleTime: 2000,
    ...options,
  });

  return {
    portfolios,
    isLoading,
  };
};

export default function PortfolioSelector({ selected, onChange }) {
  const { t } = useTranslation(["dashboard"]);

  const { portfolios, isLoading } = usePortfolios({
    onSuccess: (data) => {
      if (!selected) {
        onChange(data[0]);
      }
    },
  });

  useEffect(() => {
    if (!selected && portfolios && portfolios.length) {
      onChange(portfolios[0]);
    }
  }, []);

  const selectedPortfolio = useMemo(() => {
    if (!portfolios || !selected) {
      return null;
    }

    return portfolios.find(
      (portfolio) => portfolio.portfolioId === selected.portfolioId
    );
  }, [selected]);

  if (isLoading || !selectedPortfolio) {
    return null;
  }

  return (
    <Listbox
      value={selectedPortfolio.portfolioId}
      onChange={(option) => onChange(option)}
    >
      {({ open }) => (
        <>
          <ListboxLabel>{t("portfolios.selector.label")}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selectedPortfolio.name}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {portfolios.map((option) => (
                <ListboxOption key={option.portfolioId} value={option}>
                  {option.name}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  );
}

PortfolioSelector.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
