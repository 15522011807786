import { useMemo } from "react";
import PropTypes from "prop-types";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "../core/Listbox";

import { getCategories } from "../../loaders/categories";

const useCategories = (options) => {
  const { topCategoryStandard, includeTopCategory, includeNull } = options;

  const { data: categories, isLoading } = useQuery({
    queryKey: ["categories"],
    queryFn: () =>
      getCategories({
        topCategoryStandard,
        includeTopCategory,
        includeNull,
      }),
    ...options,
  });

  return {
    categories,
    isLoading,
  };
};

export default function CategorySelector({
  selected,
  onChange,
  topCategoryStandard,
  includeTopCategory,
  includeNull = false,
}) {
  const { t } = useTranslation(["dashboard"]);
  const { categories, isLoading } = useCategories({
    topCategoryStandard,
    includeTopCategory,
    includeNull,
    onSuccess: (data) => {
      if (!selected) {
        onChange(data[0]);
      }
    },
  });

  const selectedCategory = useMemo(() => {
    if (!categories || !selected) {
      return null;
    }

    return categories.find(
      (category) => category.categoryId === selected.categoryId
    );
  }, [selected]);

  if (isLoading || !selectedCategory) {
    return null;
  }

  return (
    <Listbox
      value={selectedCategory.categoryId}
      onChange={(option) => onChange(option)}
    >
      {({ open }) => (
        <>
          <ListboxLabel>{t("categorySelector.label")}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selectedCategory.paddedName}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {categories.map((option) => (
                <ListboxOption key={option.categoryId} value={option}>
                  {option.paddedName}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  );
}

CategorySelector.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  topCategoryStandard: PropTypes.string.isRequired,
  includeTopCategory: PropTypes.bool.isRequired,
  includeNull: PropTypes.bool,
};
