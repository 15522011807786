import { createContext, useContext, useId, useMemo } from "react";
import PropTypes from "prop-types";

const FieldContext = createContext({});

export const useFieldContext = () => {
  return useContext(FieldContext);
};

const InputField = ({ id, children }) => {
  const uuid = useId();
  const value = useMemo(() => ({ id: id || uuid }), [id, uuid]);

  return (
    <FieldContext.Provider value={value}>{children}</FieldContext.Provider>
  );
};

InputField.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};

export default InputField;
