import _ from "lodash";
import assert from "assert";

export default (buildings) => {
  return {
    headerName: "Building",
    resizable: true,
    cellEditor: "agRichSelectCellEditor",
    cellEditorPopup: true,
    valueGetter: function (params) {
      return _.get(params, ["data", "building", "buildingReference"]);
    },
    cellEditorParams: function () {
      const values = buildings.map((building) => building.buildingReference);

      return {
        values,
      };
    },
    valueSetter: (params) => {
      const { newValue } = params;

      if (newValue) {
        const building = buildings.find(
          (building) => building.buildingReference === newValue
        );

        assert(building, `No building found corresponding to ${newValue}`);

        params.data.building = building;
        params.data.buildingId = building.buildingId;

        return true;
      }

      return false;
    },
  };
};
