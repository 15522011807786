import PropTypes from "prop-types";

import { forwardRef } from "react";

import AgGridReact from "../../Grid/Grid";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

const TimelinesGrid = forwardRef(
  ({ timelines, onTimelinesGridClick, getStyling }, gridRef) => {
    const columnDefs = [
      {
        headerName: "Name",
        field: "name",
        width: 350,
        cellStyle({ data }) {
          const { color, fontWeight } = getStyling({
            name: data.name,
            building: data.building,
            isBudget: data.isBudget,
            category: data.category,
          });

          return {
            color,
            fontWeight,
          };
        },
      },
    ];

    for (const { xAxisLabel } of timelines[0].observations) {
      columnDefs.push({
        headerName: xAxisLabel,
        field: xAxisLabel,
      });
    }

    const data = [];
    for (const {
      name,
      observations,
      building,
      category,
      isBudget,
    } of timelines) {
      const row = {
        name,
        building,
        category,
        isBudget,
      };

      for (const { y, xAxisLabel } of observations) {
        row[xAxisLabel] = y;
      }

      data.push(row);
    }

    return (
      <AgGridReact
        domLayout="autoHeight"
        modules={[ClientSideRowModelModule, ExcelExportModule]}
        columnDefs={columnDefs}
        rowData={data}
        onCellClicked={onTimelinesGridClick}
        ref={gridRef}
      ></AgGridReact>
    );
  }
);

TimelinesGrid.propTypes = {
  timelines: PropTypes.array.isRequired,
  onTimelinesGridClick: PropTypes.func,
  getStyling: PropTypes.func.isRequired,
};

TimelinesGrid.displayName = "Grid";

export default TimelinesGrid;
