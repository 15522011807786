import PropTypes from "prop-types";
import classnames from "classnames";

const styles = {
  variant: {
    primary:
      "border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50",
    neutral: "border-gray-300 text-gray-700 shadow-sm hover:bg-gray-50",
    negative:
      "border-red-600 bg-red-100 text-gray-700 shadow-sm hover:bg-red-50",
  },
  size: {
    md: "px-4 py-2 text-sm font-medium",
  },
};

export const Button = ({
  as: Component,
  isFull,
  children,
  variant,
  size,
  className,
  ...props
}) => {
  return (
    <Component
      type="button"
      className={classnames(
        "inline-flex items-center rounded-md border",
        "focus:outline-none focus:ring-2 focus:ring-ps-500 focus:ring-offset-2",
        styles.variant[variant],
        styles.size[size],
        className,
        {
          "w-full justify-center": isFull,
        }
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

Button.propTypes = {
  as: PropTypes.elementType,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isFull: PropTypes.bool,
  size: PropTypes.oneOf(["md"]).isRequired,
  variant: PropTypes.oneOf(["primary", "negative", "neutral"]).isRequired,
};

Button.defaultProps = {
  as: "button",
  size: "md",
  variant: "primary",
};

export default Button;
