import App from "./components/App/App";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { Navigate } from "react-router-dom";

import Metrics from "./routes/Metrics";
import InternalBenchmarks from "./routes/InternalBenchmarks";
import Buildings from "./routes/Buildings";
import BuildingsDrilldown from "./routes/BuildingsDrilldown";
import RecordsDrilldown from "./routes/RecordsDrilldown";
import TimelinesDrilldown from "./routes/TimelinesDrilldown";
import ExpenseOutliersDrilldown from "./routes/ExpenseOutliersDrilldown";
import AccountCharts from "./routes/AccountCharts";
import CreateAccountChart from "./routes/CreateAccountChart";
import CreateBuilding from "./routes/CreateBuilding";
import CreateMetric from "./routes/CreateMetric";
import CreateRecord from "./routes/CreateRecord";
import CreateNoiDefinition from "./routes/CreateNoiDefinition";
import Login from "./routes/Login";
import Logout from "./routes/Logout";
import Records from "./routes/Records";
import Result from "./routes/Result";
import Drilldown from "./routes/Drilldown";
import Setup from "./routes/Setup";
import Apps from "./routes/Apps";
import BusinessCentralApp from "./routes/BusinessCentralApp";

import RootErrorBoundary from "./components/RootErrorBoundary";

const routes = [
  {
    path: "/login",
    element: <Login />,
    errorElement: <RootErrorBoundary />,
  },
  {
    path: "/logout",
    element: <Logout />,
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        element: <App />,
        children: [
          {
            path: "/setup",
            element: <Setup />,
            children: [
              {
                path: "/setup/buildings/new",
                element: <CreateBuilding />,
              },
              {
                path: "/setup/buildings",
                element: <Buildings />,
              },
              {
                path: "/setup/account_charts/new",
                element: <CreateAccountChart />,
              },
              {
                path: "/setup/account_charts",
                element: <AccountCharts />,
              },
              {
                path: "/setup/noi_definitions/new",
                element: <CreateNoiDefinition />,
              },
              {
                path: "/setup/records/new",
                element: <CreateRecord />,
              },
              {
                path: "/setup/records",
                element: <Records />,
              },
              {
                path: "/setup/apps/businessCentral",
                element: <BusinessCentralApp />,
              },
              {
                path: "/setup/apps",
                element: <Apps />,
              },
              {
                path: "/setup/metrics/new",
                element: <CreateMetric />,
              },
              { path: "/setup/metrics", element: <Metrics /> },
              {
                path: "/setup/internal_benchmarks",
                element: <InternalBenchmarks />,
              },
              {
                path: "/setup",
                element: <Navigate to="/setup/buildings" replace />,
              },
            ],
          },
          {
            path: "/drilldown",
            element: <Drilldown />,
            children: [
              {
                path: "/drilldown/timelines",
                element: <TimelinesDrilldown />,
              },
              {
                path: "/drilldown/records",
                element: <RecordsDrilldown />,
              },
              {
                path: "/drilldown/expense_outliers",
                element: <ExpenseOutliersDrilldown />,
              },
              {
                path: "/drilldown/buildings",
                element: <BuildingsDrilldown />,
              },
            ],
          },
          {
            path: "/",
            element: <Result />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

export default routes;
