import PropTypes from "prop-types";
import classnames from "classnames";

import { Switch as HeadlessSwitch } from "@headlessui/react";

export const SwitchGroup = HeadlessSwitch.Group;

export const SwitchLabel = ({ className, ...props }) => (
  <HeadlessSwitch.Label
    className={classnames(
      "block text-base font-medium text-gray-900 mb-2",
      className
    )}
    {...props}
  />
);

SwitchLabel.propTypes = {
  className: PropTypes.string,
};

export const Switch = ({ enabled, onChange }) => (
  <HeadlessSwitch
    checked={enabled}
    onChange={onChange}
    className={classnames(
      enabled ? "bg-green-600" : "bg-gray-200",
      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-offset-2"
    )}
  >
    <span className="sr-only">Use setting</span>
    <span
      aria-hidden="true"
      className={classnames(
        enabled ? "translate-x-5" : "translate-x-0",
        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
      )}
    />
  </HeadlessSwitch>
);

Switch.propTypes = {
  enabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
