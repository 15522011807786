import PropTypes from "prop-types";
import { forwardRef, useCallback, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import useResizeObserver from "use-resize-observer";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import AgGridReact from "../Grid/Grid";

import { updateNoiDefinition } from "../../loaders/noiDefinitions";

import { Name } from "./columns";

const Grid = forwardRef(({ noiDefinitions, refetch }, gridRef) => {
  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        });
      }
    },
  });

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      });
    }
  }, []);

  const updateMutation = useMutation({
    mutationFn: updateNoiDefinition,
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells();
      });
    },
  });

  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        suppressMovable: true,
        editable: true,
      },
      columnDefs: [Name],
      rowSelection: "multiple",
      onCellValueChanged: async function ({ data }) {
        updateMutation.mutate(data);
      },
    }),
    [noiDefinitions]
  );

  return (
    <AgGridReact
      domLayout="autoHeight"
      gridOptions={gridOptions}
      modules={[ClientSideRowModelModule]}
      onFirstDataRendered={onFirstDataRendered}
      ref={gridRef}
      rowClass="c-grid-row"
      rowData={noiDefinitions}
    />
  );
});

Grid.propTypes = {
  noiDefinitions: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
};

Grid.displayName = "Grid";

export default Grid;
