import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import _ from "lodash";

import { useQuery } from "@tanstack/react-query";
import { getAttributes } from "../../loaders/attributes";

import Page from "../Page/Page";
import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOptions,
  ListboxOption,
} from "../core/Listbox";

const BuildingFilterDropdown = ({ title, options, selected, onChange }) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <ListboxLabel>{title}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selected.name}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {options.map((option) => (
                <ListboxOption key={option.id} value={option}>
                  {option.name}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  );
};

BuildingFilterDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export const useBuildingFilters = () => {
  const [selection, setSelection] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: attributes, isLoading } = useQuery(
    ["attributes"],
    getAttributes,
    {
      onSuccess: (data) => {
        const attributesById = data.reduce((acc, curr) => {
          acc[curr.id] =
            curr.options.find(
              (option) => option.id === searchParams.get(curr.id)
            ) || curr.options[0];

          return acc;
        }, {});

        setSelection(attributesById);
      },
    }
  );

  const onChange = useCallback(
    (attribute, selected) => {
      setSelection((prev) => ({
        ...prev,
        [attribute.id]: selected,
      }));
    },
    [setSelection]
  );

  useEffect(() => {
    const currentValue = Object.keys(selection).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: selection[curr].id,
      }),
      []
    );

    setSearchParams(qs.stringify(currentValue));
  }, [selection]);

  return {
    attributes: attributes || [],
    selection,
    onChange,
    isLoading: isLoading || _.isEmpty(selection),
  };
};

const BuildingFilters = ({ attributes, selection, onChange }) => {
  const { t } = useTranslation(["dashboard"]);

  return (
    <Page.Section id="buildingFilters">
      <Page.Section.Title>
        {t("overview.buildingFilters.title")}
      </Page.Section.Title>
      <Page.Section.Content>
        <div className="grid grid-cols-3 gap-5">
          {attributes.map((attribute) => (
            <div key={attribute.id}>
              <BuildingFilterDropdown
                title={attribute.name}
                options={attribute.options}
                selected={selection[attribute.id]}
                onChange={(selected) => onChange(attribute, selected)}
              />
            </div>
          ))}
        </div>
      </Page.Section.Content>
    </Page.Section>
  );
};

BuildingFilters.propTypes = {
  attributes: PropTypes.array.isRequired,
  selection: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BuildingFilters;
