import { memo } from "react";
import PropTypes from "prop-types";

import Spinner from "../Spinner";

const AG_GRID_LICENSE_KEY = process.env.REACT_APP_AG_GRID_LICENSE_KEY;

import { LicenseManager } from "@ag-grid-enterprise/core";
LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);
import { AgGridReact } from "@ag-grid-community/react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import "../../assets/css/ag-grid.css";

export const LoadingCellRenderer = memo(({ loadingMessage }) => (
  <div className="w-full h-full pl-12 flex items-center justify-start">
    <Spinner size="sm" />
    <span>{loadingMessage}</span>
  </div>
));

LoadingCellRenderer.propTypes = {
  loadingMessage: PropTypes.string,
};

LoadingCellRenderer.displayName = "LoadingCellRenderer";

export default AgGridReact;
