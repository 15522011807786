import PropTypes from "prop-types";
import { useRef } from "react";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";

import AgGridReact from "../../Grid/Grid";

import "../../../assets/css/timelines-buildings.css";

const BuildingsGrid = ({
  yearIntervalData,
  onBuildingsGridClick,
  getStyling,
  building,
}) => {
  const gridRef = useRef(null);

  const columnDefs = [
    {
      headerName: "Building",
      width: 250,
      cellStyle({ data }) {
        const { payload } = data;

        const { color, fontWeight } = getStyling({
          name: data.name,
          building: payload.building,
          isBudget: payload.isBudget,
        });

        return {
          color,
          fontWeight,
        };
      },
      cellRenderer({ data }) {
        const { name } = data;

        if (name === "Trend") {
          return <p className="line-styling-trend">{name}</p>;
        } else if (name === "Average") {
          return <p className="line-styling-average">{name}</p>;
        } else {
          if (
            building &&
            building.buildingId === data.payload.building.buildingId
          ) {
            return <p className="line-styling-selected">{name}</p>;
          }

          return <p className="line-styling-regular">{name}</p>;
        }
      },
    },
    {
      field: "value",
      headerName: "Actual",
      width: 100,
    },
  ];

  return (
    <AgGridReact
      modules={[ClientSideRowModelModule]}
      rowData={yearIntervalData}
      rowClass="c-grid-row"
      onRowClicked={onBuildingsGridClick}
      columnDefs={columnDefs}
      gridOptions={{
        suppressHorizontalScroll: true,
      }}
      ref={gridRef}
    ></AgGridReact>
  );
};

BuildingsGrid.propTypes = {
  yearIntervalData: PropTypes.array.isRequired,
  onBuildingsGridClick: PropTypes.func.isRequired,
  getStyling: PropTypes.func.isRequired,
  building: PropTypes.object,
};

export default BuildingsGrid;
