import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import {
  Squares2X2Icon,
  WrenchScrewdriverIcon,
  MagnifyingGlassCircleIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { NavLink, useMatch } from "react-router-dom";

const navigation = [
  {
    label: "nav.overview",
    href: "/",
    icon: Squares2X2Icon,
  },
  {
    label: "nav.drilldown",
    href: "/drilldown/timelines",
    icon: MagnifyingGlassCircleIcon,
    children: [
      { label: "nav.timelinesDrilldown", href: "/drilldown/timelines" },
      { label: "nav.recordsDrilldown", href: "/drilldown/records" },
      {
        label: "nav.expenseOutliersDrilldown",
        href: "/drilldown/expense_outliers",
      },
      { label: "nav.buildingsDrilldown", href: "/drilldown/buildings" },
    ],
  },
  {
    label: "nav.dataRoom",
    href: "/setup/buildings",
    icon: WrenchScrewdriverIcon,
    children: [
      { label: "nav.buildings", href: "/setup/buildings" },
      { label: "nav.accountCharts", href: "/setup/account_charts" },
      { label: "nav.records", href: "/setup/records" },
      { label: "nav.metrics", href: "/setup/metrics" },
      { label: "nav.internalBenchmarks", href: "/setup/internal_benchmarks" },
      { label: "nav.apps", href: "/setup/apps" },
    ],
  },
];

const SubNav = ({ item }) => {
  const { t } = useTranslation();
  const match = useMatch("/setup/*");
  const [isOpen, setIsOpen] = useState(() => !!match);

  useEffect(() => {
    setIsOpen(!!match);
  }, [match]);

  return (
    <Fragment key={item.label}>
      <NavLink
        key={item.label}
        to={item.href}
        onClick={() => setIsOpen((prev) => !prev)}
        className={classnames(
          "group w-full flex items-center px-4 pl-7 py-4 text-sm text-ps-600",
          {
            "font-bold text-ps-900": !!match,
            "font-medium border-white hover:bg-ps-100": !match,
          }
        )}
      >
        <item.icon
          className="mr-3 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
        <span className="flex-1 text-start">{t(item.label)}</span>
        <ChevronDownIcon
          aria-hidden
          className={classnames(
            "ml-3 h-4 w-4 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400",
            {
              "text-ps-900": isOpen,
              "text-ps-600  -rotate-90": !isOpen,
            }
          )}
        />
      </NavLink>

      {isOpen &&
        item.children.map((subItem) => (
          <NavLink
            key={`${item.label}-${subItem.label}`}
            to={subItem.href}
            className={({ isActive }) =>
              classnames(
                "group flex w-full items-center px-6 pl-16 py-4 text-sm font-medium text-ps-600 border-l-4 hover:bg-ps-50 hover:border-ps-300",
                {
                  "border-ps-900 text-ps-900": isActive,
                  "border-white hover:bg-ps-100 hover:border-ps-300": !isActive,
                }
              )
            }
          >
            {t(subItem.label)}
          </NavLink>
        ))}
    </Fragment>
  );
};

SubNav.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    children: PropTypes.array.isRequired,
  }),
};

const Sidebar = () => {
  const { t } = useTranslation();

  return (
    <div className="w-64 flex flex-col bg-white border-r-2 border-ps-100">
      <div className="flex flex-grow flex-col overflow-y-auto">
        <div className="mt-5 flex flex-1 flex-col">
          <nav className="flex-1 space-y-1 px-0 pb-4">
            {navigation.map((item) =>
              item.children ? (
                <SubNav key={item.href} item={item} />
              ) : (
                <NavLink
                  key={item.label}
                  to={item.href}
                  className={({ isActive }) =>
                    classnames(
                      "group flex items-center px-6 py-4 text-sm text-ps-600 border-l-4",
                      {
                        "font-bold border-ps-900 text-ps-900": isActive,
                        "font-medium border-white hover:bg-ps-100 hover:border-ps-300":
                          !isActive,
                      }
                    )
                  }
                >
                  <item.icon
                    className="mr-3 h-6 w-6 flex-shrink-0"
                    aria-hidden="true"
                  />
                  {t(item.label)}
                </NavLink>
              )
            )}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
