import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";

import { getAccountsData, deleteAccountChart } from "../loaders/accountCharts";
import { getNoiDefinitions } from "../loaders/noiDefinitions";

import Button from "../components/core/Button/Button";

import AccountChartSelector from "../components/AccountChartSelector/AccountChartSelector";
import Page from "../components/Page/Page";
import Spinner from "../components/Spinner";
import Grid from "../components/AccountCharts/Grid";
import BuildingsSelector from "../components/BuildingsSelector/BuildingsSelector";
import NoiDefinitions from "../components/NoiDefinitions/NoiDefinitions";

export default function Accounts() {
  const gridRef = useRef(null);
  const { t } = useTranslation(["dashboard"]);

  const navigate = useNavigate();
  const [selectedAccountChart, setSelectedAccountChart] = useState(null);

  const { data: accounts, isLoading: isAccountsLoading } = useQuery(
    {
      queryKey: ["accounts", selectedAccountChart?.accountChartId],
      queryFn: () => getAccountsData(selectedAccountChart),
      enabled: !!selectedAccountChart,
    },
    [selectedAccountChart]
  );

  const { data: noiDefinitions, isLoading: isNoiDefinitionsLoading } = useQuery(
    {
      queryKey: ["noiDefinitions"],
      queryFn: getNoiDefinitions,
    }
  );

  const mutation = useMutation({
    mutationFn: deleteAccountChart,
    onSuccess: () => {
      navigate(0);
    },
  });

  const onDelete = useCallback(async () => {
    if (selectedAccountChart) {
      mutation.mutate(selectedAccountChart);
    }
  });

  return (
    <>
      <Page.Header
        actions={
          <>
            <Button variant="negative" onClick={onDelete}>
              {t("accountCharts.delete")}
            </Button>

            <Button as={Link} to="/setup/account_charts/new" className="ml-2">
              {t("accountCharts.createAccountChart")}
            </Button>
          </>
        }
      >
        {t("accountCharts.title")}
      </Page.Header>
      <Page.Section id="noiDefinitions">
        <Page.Section.Content>
          <NoiDefinitions />
        </Page.Section.Content>
      </Page.Section>
      <Page.Section id="selectedAccountChart">
        <Page.Section.Content>
          <AccountChartSelector
            selected={selectedAccountChart}
            onChange={setSelectedAccountChart}
          />
        </Page.Section.Content>
      </Page.Section>
      {selectedAccountChart && (
        <div>
          <Page.Section id="accountChartBuildings">
            <Page.Section.Content>
              {isAccountsLoading || isNoiDefinitionsLoading ? (
                <div className="flex justify-center">
                  <Spinner />
                </div>
              ) : (
                <BuildingsSelector accountChart={selectedAccountChart} />
              )}
            </Page.Section.Content>
          </Page.Section>
          <Page.Section id="accounts">
            <Page.Section.Content>
              {isAccountsLoading || isNoiDefinitionsLoading ? (
                <div className="flex justify-center">
                  <Spinner />
                </div>
              ) : (
                <Grid
                  accounts={accounts}
                  noiDefinitions={noiDefinitions}
                  ref={gridRef}
                />
              )}
            </Page.Section.Content>
          </Page.Section>
        </div>
      )}
    </>
  );
}
