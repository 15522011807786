import { useMemo } from "react";
import PropTypes from "prop-types";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "../core/Listbox";

import { getNoiDefinitions } from "../../loaders/noiDefinitions";

const useNodefinitions = (options) => {
  const { data: noiDefinitions, isLoading } = useQuery({
    queryKey: ["accountCharts"],
    queryFn: getNoiDefinitions,
    ...options,
  });

  return {
    noiDefinitions,
    isLoading,
  };
};

export default function NoiDefinitionSelector({ selected, onChange }) {
  const { t } = useTranslation(["dashboard"]);
  const { noiDefinitions, isLoading } = useNodefinitions({
    onSuccess: (data) => {
      if (!selected) {
        onChange(data[0]);
      }
    },
  });

  const selectedNoiDefinition = useMemo(() => {
    if (!noiDefinitions || !selected) {
      return null;
    }

    return noiDefinitions.find(
      (noiDefinition) =>
        noiDefinition.noiDefinitionId === selected.noiDefinitionId
    );
  }, [selected]);

  if (isLoading || !selectedNoiDefinition) {
    return null;
  }

  return (
    <Listbox
      value={selectedNoiDefinition.noiDefinitionId}
      onChange={(option) => onChange(option)}
    >
      {({ open }) => (
        <>
          <ListboxLabel>{t("noiDefinitions.selector.label")}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selectedNoiDefinition.name}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {noiDefinitions.map((option) => (
                <ListboxOption key={option.noiDefinitionId} value={option}>
                  {option.name}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  );
}

NoiDefinitionSelector.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
