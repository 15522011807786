export default {
  headerName: "Type",
  valueGetter: ({ data }) => {
    const { category } = data;

    if (!category) {
      return;
    }

    return category.type;
  },
  cellStyle: {
    color: "green",
  },
};
