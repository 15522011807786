import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";

import Button from "../core/Button/Button";
import Card from "../Card/Card";

import Grid from "./Grid";
import Page from "../Page/Page";

import {
  getNoiDefinitions,
  bulkDeleteNoiDefinitions,
} from "../../loaders/noiDefinitions";

const NoiDefinitions = () => {
  const { t } = useTranslation(["dashboard"]);
  const gridRef = useRef(null);

  const navigate = useNavigate();
  const {
    data: noiDefinitions,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["noiDefinitions"],
    queryFn: getNoiDefinitions,
    staleTime: 2000,
  });

  const createNoiDefinition = () => {
    return;
  };

  const deleteMutation = useMutation({
    mutationFn: bulkDeleteNoiDefinitions,
    onSuccess: async () => {
      navigate(0);
    },
  });

  const onDeleteNoiDefinitions = useCallback(() => {
    const selection = gridRef.current.api.getSelectedRows();

    deleteMutation.mutate(
      selection.map((noiDefinition) => noiDefinition.noiDefinitionId)
    );
  });

  if (isLoading || !gridRef) {
    return null;
  }

  return (
    <>
      <Page.Section id="noiDefinitions">
        <Card>
          <Page.Section.Title>{t("noiDefinitions.title")}</Page.Section.Title>
          <Page.Section.Content>
            <div key="createNoiDefinition" className="flex space-x-2">
              <Button
                as={Link}
                to="/setup/noi_definitions/new"
                onClick={createNoiDefinition}
                className="ml-2"
              >
                {t("noiDefinitions.create")}
              </Button>
              <Button variant="negative" onClick={onDeleteNoiDefinitions}>
                {t("noiDefinitions.delete")}
              </Button>
            </div>
          </Page.Section.Content>
          <Page.Section.Content className="ag-theme-alpine">
            <Grid
              noiDefinitions={noiDefinitions}
              ref={gridRef}
              refetch={refetch}
            />
          </Page.Section.Content>
        </Card>
      </Page.Section>
    </>
  );
};

export default NoiDefinitions;
