import _ from "lodash";

import { getResultColor } from "../../../utils/color/result";
import { getGridValueFormatter } from "../../../utils/number";

import "../../../assets/css/ag-grid.css";

export const getRowGroupId = ({
  buildingReference,
  rowName,
  category,
  record,
}) => {
  let id;
  if (category) {
    id = `category_${category.categoryId}`;
  } else if (record) {
    id = `record_${record.recordId}`;
  }

  return [buildingReference, rowName, id].join(":");
};

export const getRowData = (rows = []) => {
  const rowData = [];

  for (const row of rows) {
    const { hasChildCategoriesRows, hasChildRecordsRows } = row;

    const rowGroupId = getRowGroupId(row);

    rowData.push({
      ...row,
      group: Boolean(hasChildCategoriesRows || hasChildRecordsRows),
      rowGroupId,
      backgroundColor: getResultColor(row),
    });
  }

  return rowData;
};

export const aggFuncs = {
  sum: (params) => params.values.reduce((acc, curr) => acc + curr, 0),
};

export const getAutoGroupColumnDef = () => ({
  headerName: "Buildings",
  cellClass: "c-grid-cell",
  field: "rowGroupId",
  cellRendererParams: {
    innerRenderer: (params) => {
      return params.data.rowName;
    },
  },
});

export const getColumnDefs = () => {
  return [
    {
      headerName: "Actual",
      suppressSizeToFit: true,
      maxWidth: 160,
      headerClass: "c-grid-header--numeric",
      cellStyle: ({ data }) => {
        const { backgroundColor } = data;

        return {
          backgroundColor,
        };
      },
      cellClass: (params) => {
        if (!params.data) {
          return null;
        }

        return ["c-grid-cell", "c-grid-cell--numeric"];
      },
      valueGetter: function (params) {
        if (params.data.record && params.data.record.oneOff) {
          return;
        }

        return _.get(params, ["data", "actual"]);
      },
      valueFormatter: function (params) {
        if (params.data.record && params.data.record.oneOff) {
          return;
        }

        const formatter = getGridValueFormatter("actual");

        return formatter(params);
      },
    },
    {
      headerName: "One-off",
      suppressSizeToFit: true,
      maxWidth: 180,
      headerClass: "c-grid-header--numeric",
      cellClass: ["c-grid-cell", "c-grid-cell--numeric"],
      valueGetter: function (params) {
        let field = "oneOff";
        if (params.data.record) {
          if (!params.data.record.oneOff) {
            return;
          }

          field = "actual";
        }

        return _.get(params, ["data", field]);
      },
      valueFormatter: function (params) {
        let field = "oneOff";
        if (params.data.record) {
          if (!params.data.record.oneOff) {
            return;
          }

          field = "actual";
        }

        const formatter = getGridValueFormatter(field);

        return formatter(params);
      },
    },
    {
      headerName: "Budget",
      field: "budget",
      suppressSizeToFit: true,
      maxWidth: 160,
      headerClass: "c-grid-header--numeric",
      cellClass: ["c-grid-cell", "c-grid-cell--numeric"],
      valueFormatter: getGridValueFormatter("budget"),
    },
    {
      headerName: "Underwriting",
      field: "underwriting",
      suppressSizeToFit: true,
      maxWidth: 160,
      headerClass: "c-grid-header--numeric",
      cellClass: ["c-grid-cell", "c-grid-cell--numeric"],
      valueFormatter: getGridValueFormatter("underwriting"),
    },
    {
      field: "categoryId",
      hide: true,
    },
  ];
};
