import { forwardRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { updateRecord } from "../../loaders/records";

import Page from "../Page/Page";
import AgGridReact from "../Grid/Grid";

import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import useResizeObserver from "use-resize-observer";

import {
  Amount,
  ProcessingDate,
  Text,
  TextLocale,
  RecordId,
  ExternalRecordId,
  SyncComment,
  BuildingReference,
  BuildingOwnerAction,
  BusinessOrigin,
  Type,
  Entry,
  Concerning,
  Specifics,
  AccountNumber,
  AccountName,
} from "./columns";

const Grid = forwardRef(({ data, buildings }, gridRef) => {
  const { categories, records } = data;

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        });
      }
    },
  });

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      });
    }
  }, []);

  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        editable: true,
        suppressMovable: true,
      },
      columnDefs: [
        RecordId,
        ExternalRecordId,
        ProcessingDate,
        Text,
        SyncComment,
        BuildingReference(buildings),
        BusinessOrigin(categories),
        Type(categories),
        Entry(categories),
        Concerning(categories),
        Specifics(categories),
        AccountNumber,
        AccountName,
        BuildingOwnerAction,
        TextLocale,
        Amount,
      ],
      onCellValueChanged: async function ({ data }) {
        await updateRecord(data);
      },
      rowSelection: "multiple",
    }),
    [categories]
  );

  return (
    <Page.Section.Content className="ag-theme-alpine">
      <AgGridReact
        domLayout="autoHeight"
        gridOptions={gridOptions}
        modules={[
          ClientSideRowModelModule,
          RichSelectModule,
          ExcelExportModule,
        ]}
        onFirstDataRendered={onFirstDataRendered}
        ref={gridRef}
        rowClass="c-grid-row"
        rowData={records}
      />
    </Page.Section.Content>
  );
});

Grid.propTypes = {
  data: PropTypes.shape({
    accounts: PropTypes.array,
    categories: PropTypes.array,
    records: PropTypes.array,
  }),
  buildings: PropTypes.array,
};

Grid.displayName = "Grid";

export default Grid;
