import { useMemo } from "react";
import PropTypes from "prop-types";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getMetrics } from "../../loaders/metrics";

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "../core/Listbox";

const useMetrics = (options) => {
  const { data: metrics, isLoading } = useQuery({
    queryKey: ["metrics"],
    queryFn: getMetrics,
    staleTime: 2000,
    ...options,
  });

  return {
    metrics,
    isLoading,
  };
};

export default function MetricSelector({ selected, onChange }) {
  const { t } = useTranslation(["dashboard"]);

  const { metrics, isLoading } = useMetrics({
    onSuccess: (data) => {
      if (!selected) {
        onChange(data[0]);
      }
    },
  });

  const selectedMetric = useMemo(() => {
    if (!metrics || !selected) {
      return null;
    }

    return metrics.find((metric) => metric.metricId === selected.metricId);
  }, [selected]);

  if (isLoading || !selectedMetric) {
    return null;
  }

  return (
    <Listbox
      value={selectedMetric.metricId}
      onChange={(option) => onChange(option)}
    >
      {({ open }) => (
        <>
          <ListboxLabel>{t("metrics.selector.label")}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>{selectedMetric.fullName}</ListboxButton>
            <ListboxOptions isOpen={open}>
              {metrics.map((option) => (
                <ListboxOption key={option.metricId} value={option}>
                  {option.fullName}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  );
}

MetricSelector.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
