import _ from "lodash";
import { useRef, useMemo, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Page from "../../Page/Page";

import {
  getHeatmap,
  getChildCategoriesRows,
  getChildRecordsRows,
} from "../../../loaders/heatmap";
import { updateRecord } from "../../../loaders/records";

import { getAutoGroupColumnDef, getColumnDefs, getRowData } from "./utils";

import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";

import useResizeObserver from "use-resize-observer";

import Button from "../../core/Button/Button";
import AgGridReact, { LoadingCellRenderer } from "../../Grid/Grid";
import ErrorBoundary from "../../ErrorBoundary/ErrorBoundary";

const Heatmap = ({
  portfolio,
  noiDefinition,
  buildingFilters,
  timeFilter,
  absolute,
  excludeIncompleteMonths,
  gridRef,
}) => {
  const resizeRef = useRef(null);

  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        });
      }
    },
  });

  const rowFetcher = useMemo(() => {
    return (params, parentNode) => {
      if (params.groupKeys.length) {
        const categoryId = params.groupKeys.at(-1).split(":")[2];

        const {
          buildingId,
          noiDefinition,
          hasChildCategoriesRows,
          hasChildRecordsRows,
        } = parentNode.data;

        if (hasChildRecordsRows) {
          return getChildRecordsRows(
            buildingId,
            categoryId,
            noiDefinition.noiDefinitionId,
            timeFilter,
            absolute,
            excludeIncompleteMonths
          );
        } else if (hasChildCategoriesRows) {
          return getChildCategoriesRows(
            buildingId,
            categoryId,
            noiDefinition.noiDefinitionId,
            timeFilter,
            absolute,
            excludeIncompleteMonths
          );
        }
      }

      return getHeatmap(
        portfolio.portfolioId,
        noiDefinition.noiDefinitionId,
        buildingFilters,
        timeFilter,
        absolute,
        excludeIncompleteMonths
      );
    };
  }, [
    portfolio,
    noiDefinition,
    buildingFilters,
    timeFilter,
    absolute,
    excludeIncompleteMonths,
  ]);

  const createServerSideDataSource = useCallback(() => {
    return {
      getRows: async (params) => {
        try {
          const data = await rowFetcher(params.request, params.parentNode);

          params.success({ rowData: getRowData(data) });
        } catch (error) {
          params.fail();
        }
      },
    };
  }, [rowFetcher]);

  const refreshCache = (route) => {
    gridRef.current.api.refreshServerSide({
      route: route,
      purge: false,
    });
  };

  useEffect(() => {
    if (gridRef.current?.api) {
      const datasource = createServerSideDataSource();
      gridRef.current.api.setServerSideDatasource(datasource);

      refreshCache([]);
    }
  }, [portfolio, noiDefinition, buildingFilters, timeFilter, refreshCache]);

  const {
    autoGroupColumnDef,
    columnDefs,
    getRowId,
    gridOptions,
    getServerSideGroupKey,
    isServerSideGroup,
  } = useMemo(() => {
    return {
      autoGroupColumnDef: getAutoGroupColumnDef(),
      columnDefs: getColumnDefs(),
      getRowId: ({ data }) => data.rowGroupId,
      getServerSideGroupKey: ({ category, buildingReference }) =>
        `${buildingReference}:${category.standard}:${category.categoryId}`,
      isServerSideGroup: (item) => item.group,
      gridOptions: {
        rowClass: "cell-default",
        rowHeight: 56,
      },
    };
  }, []);

  const onRowClicked = (params) => {
    const data = params.data;

    if (params.type === "rowClicked" && data.record) {
      data.record.oneOff = !data.record.oneOff;

      const node = params.node;

      const parentNodesKeys = [];
      for (let level = node.level; level > 0; level--) {
        const parent = _.get(node, new Array(level).fill("parent"));

        parentNodesKeys.push(parent.key);
      }

      updateRecord(data.record);

      params.node.setData(data);

      for (let level = 0; level <= parentNodesKeys.length; level++) {
        refreshCache(parentNodesKeys.slice(0, level));
      }
    }
  };

  const onGridReady = useCallback((params) => {
    const datasource = createServerSideDataSource();
    params.api.setServerSideDatasource(datasource);
  }, []);

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      });
    }
  }, []);

  return (
    <>
      <ErrorBoundary>
        <Page.Section.Content ref={resizeRef} className="ag-theme-alpine">
          <AgGridReact
            ref={gridRef}
            autoGroupColumnDef={autoGroupColumnDef}
            columnDefs={columnDefs}
            modules={[
              ServerSideRowModelModule,
              RowGroupingModule,
              ExcelExportModule,
            ]}
            rowClass="c-grid-row"
            loadingCellRenderer={LoadingCellRenderer}
            getRowId={getRowId}
            gridOptions={gridOptions}
            domLayout="autoHeight"
            onFirstDataRendered={onFirstDataRendered}
            treeData
            animateRows
            isServerSideGroup={isServerSideGroup}
            getServerSideGroupKey={getServerSideGroupKey}
            onGridReady={onGridReady}
            onRowClicked={onRowClicked}
            rowModelType="serverSide"
          />
        </Page.Section.Content>
      </ErrorBoundary>
    </>
  );
};

Heatmap.propTypes = {
  portfolio: PropTypes.object.isRequired,
  noiDefinition: PropTypes.object.isRequired,
  buildingFilters: PropTypes.object.isRequired,
  timeFilter: PropTypes.object.isRequired,
  absolute: PropTypes.bool.isRequired,
  excludeIncompleteMonths: PropTypes.bool.isRequired,
  gridRef: PropTypes.object.isRequired,
  attributes: PropTypes.object.isRequired,
};

const HeatmapSection = ({
  portfolio,
  noiDefinition,
  buildingFilters,
  timeFilter,
  attributes,
  absolute,
  excludeIncompleteMonths,
}) => {
  const { t } = useTranslation(["dashboard"]);

  const gridRef = useRef();

  const onDownload = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  });

  const attributesByKey = useMemo(() => {
    return attributes.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
  }, [attributes]);

  return (
    <Page.Section id="heatmap">
      <Page.Section.Title>{t("overview.heatmap.title")}</Page.Section.Title>
      <Page.Section.Content>
        <Button onClick={onDownload} className="ml-2">
          {t("overview.heatmap.export")}
        </Button>
        <Heatmap
          noiDefinition={noiDefinition}
          portfolio={portfolio}
          timeFilter={timeFilter}
          absolute={absolute}
          excludeIncompleteMonths={excludeIncompleteMonths}
          attributes={attributesByKey}
          buildingFilters={buildingFilters}
          gridRef={gridRef}
        />
      </Page.Section.Content>
    </Page.Section>
  );
};

HeatmapSection.propTypes = {
  portfolio: PropTypes.object.isRequired,
  noiDefinition: PropTypes.object.isRequired,
  buildingFilters: PropTypes.object.isRequired,
  absolute: PropTypes.bool.isRequired,
  excludeIncompleteMonths: PropTypes.bool.isRequired,
  timeFilter: PropTypes.object.isRequired,
  attributes: PropTypes.array.isRequired,
};

export default HeatmapSection;
