import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enTranslation from "./locales/en/translation.json";

export const DEFAULT_LOCALE = "en";
export const DEFAULT_NS = "translation";

async function loadLocale(options, url, payload, callback) {
  try {
    const response = await import(`./locales/${url}.json`);
    return callback(null, { data: response.default, status: 200 });
  } catch (err) {
    return callback(err, null);
  }
}

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      allowMultiLoading: false,
      loadPath: (locales, namespaces) => {
        return locales.flatMap((locale) =>
          namespaces.map((ns) => `${locale}/${ns}`)
        )[0];
      },
      request: loadLocale,
    },
    fallbackLng: DEFAULT_LOCALE,
    lng: DEFAULT_LOCALE,
    supportedLngs: [DEFAULT_LOCALE],
    debug: true,
    ns: DEFAULT_NS,

    interpolation: {
      escapeValue: false,
    },
  });

// preload english common translations
i18n.addResourceBundle("en", "translation", enTranslation);

export default i18n;
