import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";

import { createNoiDefinition } from "../loaders/noiDefinitions";
import toast from "react-hot-toast";

import Page from "../components/Page/Page";
import Card from "../components/Card/Card";
import Button from "../components/core/Button/Button";
import InputField from "../components/core/Input/InputField";
import Label from "../components/core/Input/Label";
import Input from "../components/core/Input/Input";

const Create = () => {
  const { t } = useTranslation(["dashboard"]);

  const [name, setName] = useState("");

  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: createNoiDefinition,
  });

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      mutation.mutate(
        { name },
        {
          onSuccess: () => {
            toast.success(t("createNoiDefinition.notification.success"));
            navigate("/setup/account_charts");
          },
          onError: (error) => {
            toast.error(
              t("createNoiDefinition.notification.error", {
                error: error.toString(),
              })
            );
          },
        }
      );
    },
    [name]
  );

  return (
    <>
      <Page.Header>{t("createNoiDefinition.title")}</Page.Header>
      <Page.Section>
        <Card>
          <form
            onSubmit={onSubmit}
            className="p-6 space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("createNoiDefinition.form.title")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("createNoiDefinition.form.subtitle")}
                  </p>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div key="name" className="sm:col-span-4">
                    <InputField id="name">
                      <Label className="mb-2">
                        {t(`createNoiDefinition.field.name.title`)}
                      </Label>
                      <Input
                        type="text"
                        name="name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </InputField>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <Button onClick={() => navigate("/setup/account_charts")}>
                  {t("form.cancel", { ns: "translation" })}
                </Button>
                <Button className="ml-3" type="submit" variant="primary">
                  {t("form.submit", { ns: "translation" })}
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Page.Section>
    </>
  );
};

export default Create;
