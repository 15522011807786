import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useCallback, useState } from "react";

import Card from "../Card/Card";
import Button from "../core/Button/Button";
import InputField from "../core/Input/InputField";
import Label from "../core/Input/Label";
import Input from "../core/Input/Input";

import { useNavigate } from "react-router-dom";

import { connect } from "../../loaders/apps";

export default function BusinessCentralConnect() {
  const { t } = useTranslation(["dashboard"]);

  const [tenantId, setTenantId] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const navigate = useNavigate();
  const connectMutation = useMutation({
    mutationFn: connect,
  });

  const onSubmit = useCallback(async (event) => {
    event.preventDefault();

    if (tenantId && clientId && clientSecret) {
      connectMutation.mutate(
        {
          appId: "business_central",
          config: {
            tenantId,
            clientId,
            clientSecret,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("apps.notification.success"));
            navigate("/setup/apps/businessCentral");
          },
          onError: (error) => {
            toast.error(
              t("apps.notification.error", {
                error: error.toString(),
              })
            );
          },
        }
      );
    }
  });

  return (
    <>
      <Card>
        <form
          onSubmit={onSubmit}
          className="p-6 space-y-8 divide-y divide-gray-200"
        >
          <div className="space-y-8 divide-y divide-gray-200">
            <div>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t("apps.businessCentral.form.title")}
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  {t("apps.businessCentral.form.subtitle")}
                </p>
              </div>

              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <div key="tenantId" className="sm:col-span-4">
                  <InputField id="tenantId">
                    <Label className="mb-2">
                      {t(`apps.businessCentral.field.tenantId.title`)}
                    </Label>
                    <Input
                      type="text"
                      name="tenantId"
                      value={tenantId}
                      onChange={(event) => setTenantId(event.target.value)}
                    />
                  </InputField>
                </div>
                <div key="clientId" className="sm:col-span-4">
                  <InputField id="clientId">
                    <Label className="mb-2">
                      {t(`apps.businessCentral.field.clientId.title`)}
                    </Label>
                    <Input
                      type="text"
                      name="clientId"
                      value={clientId}
                      onChange={(event) => setClientId(event.target.value)}
                    />
                  </InputField>
                </div>
                <div key="clientSecret" className="sm:col-span-4">
                  <InputField id="clientSecret">
                    <Label className="mb-2">
                      {t(`apps.businessCentral.field.clientSecret.title`)}
                    </Label>
                    <Input
                      type="password"
                      name="clientSecret"
                      value={clientSecret}
                      onChange={(event) => setClientSecret(event.target.value)}
                    />
                  </InputField>
                </div>
              </div>
            </div>
          </div>

          <div className="pt-5">
            <div className="flex justify-end">
              <Button onClick={() => navigate("/setup/apps")}>
                {t("form.cancel", { ns: "translation" })}
              </Button>
              <Button className="ml-3" type="submit" variant="primary">
                {t("apps.form.connect")}
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </>
  );
}
