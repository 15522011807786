import httpClient from "../utils/httpClient";

export async function getAllAccountCharts() {
  const response = await httpClient.get(`/account_chart/all`);

  return response.data;
}

export async function getAccountsData({ accountChartId }) {
  const response = await httpClient.get(
    `/account_chart/${accountChartId}/accounts`
  );

  return response.data;
}

export async function getAccountChartBuildings(accountChartId) {
  const response = await httpClient.get(
    `/account_chart/${accountChartId}/buildings`
  );

  return response.data;
}

export async function setAccountChartBuildings({
  accountChartId,
  name,
  buildings,
}) {
  const buildingIds = buildings.map((building) => building.buildingId);

  await httpClient.patch(`/account_chart/${accountChartId}/update`, {
    name,
    buildingIds,
  });
}

export async function createAccountChart({ name, file }) {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("name", name);

  await httpClient.post(`/account_chart/upload`, formData);
}

export async function deleteAccountChart({ accountChartId }) {
  const response = await httpClient.delete(
    `/account_chart/${accountChartId}/delete`
  );

  return response.data;
}
