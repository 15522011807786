import { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { createBuilding } from "../loaders/buildings";
import toast from "react-hot-toast";

import Page from "../components/Page/Page";
import Card from "../components/Card/Card";
import InputField from "../components/core/Input/InputField";
import Input from "../components/core/Input/Input";
import Label from "../components/core/Input/Label";
import Button from "../components/core/Button/Button";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "../components/core/Listbox";
import { useNavigate } from "react-router-dom";

const fields = [
  {
    id: "buildingReference",
    type: "text",
    default: {
      value: "",
      label: "",
    },
  },
  {
    id: "size",
    type: "number",
    default: {
      value: "",
      label: "",
    },
  },
  {
    id: "units",
    type: "number",
    default: {
      value: "",
      label: "",
    },
  },
  {
    id: "floors",
    type: "number",
    default: {
      value: "",
      label: "",
    },
  },
  {
    id: "constructionYear",
    type: "number",
    default: {
      value: "",
      label: "",
    },
  },
  {
    id: "primaryUsage",
    type: "option",
    default: { value: "office", label: "Office" },
    options: [
      { value: "office", label: "Office" },
      { value: "residential", label: "Residential" },
      { value: "commercial", label: "Commercial" },
    ],
  },
  {
    id: "portfolioName",
    type: "text",
    default: {
      value: "",
      label: "",
    },
  },
];

const Create = () => {
  const { t } = useTranslation(["dashboard", "translation"]);

  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: createBuilding,
  });

  const [state, setState] = useState(() =>
    fields.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.default,
      }),
      {}
    )
  );

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const data = {};
      for (const [fieldId, field] of Object.entries(state)) {
        data[fieldId] = field.value;
      }

      mutation.mutate(data, {
        onSuccess: () => {
          toast.success(t("createBuilding.notification.success"));
          navigate("/setup/buildings");
        },
        onError: (error) => {
          toast.error(
            t("createBuilding.notification.error", {
              error: error.toString(),
            })
          );
        },
      });
    },
    [state]
  );

  return (
    <>
      <Page.Header>{t("createBuilding.title")}</Page.Header>
      <Page.Section>
        <Card>
          <form
            onSubmit={onSubmit}
            className="p-6 space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("createBuilding.form.title")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("createBuilding.form.subtitle")}
                  </p>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  {fields.map((field) => {
                    switch (field.type) {
                      case "option": {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <Listbox
                              value={state[field.id]}
                              onChange={(selected) =>
                                setState((prev) => ({
                                  ...prev,
                                  [field.id]: selected,
                                }))
                              }
                            >
                              {({ open }) => (
                                <>
                                  <Label className="mb-2">
                                    {t(
                                      `createBuilding.field.${field.id}.title`
                                    )}
                                  </Label>
                                  <div className="relative">
                                    <ListboxButton>
                                      {state[field.id].label}
                                    </ListboxButton>
                                    <ListboxOptions isOpen={open}>
                                      {field.options.map((option) => (
                                        <ListboxOption
                                          key={option.value}
                                          value={option}
                                        >
                                          {option.label}
                                        </ListboxOption>
                                      ))}
                                    </ListboxOptions>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          </div>
                        );
                      }
                      default: {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <InputField id={field.id}>
                              <Label className="mb-2">
                                {t(`createBuilding.field.${field.id}.title`)}
                              </Label>
                              <Input
                                type={field.type}
                                name={field.id}
                                value={state[field.id].value}
                                onChange={(event) =>
                                  setState((prev) => ({
                                    ...prev,
                                    [field.id]: { value: event.target.value },
                                  }))
                                }
                              />
                              <p className="mt-2 text-sm text-gray-500">
                                {t(
                                  `createBuilding.field.${field.id}.description`
                                )}
                              </p>
                            </InputField>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <Button onClick={() => navigate("/setup/buildings")}>
                  {t("form.cancel", { ns: "translation" })}
                </Button>
                <Button className="ml-3" type="submit" variant="primary">
                  {t("form.submit", { ns: "translation" })}
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Page.Section>
    </>
  );
};

export default Create;
