import PropTypes from "prop-types";
import classnames from "classnames";

import { useFieldContext } from "./InputField";

const styles = {
  size: {
    md: "px-3 py-2 sm:text-sm",
  },
};

const Input = ({ id, type, size, ...props }) => {
  const context = useFieldContext();

  return (
    <input
      id={id || context.id}
      type={type}
      className={classnames(
        "block w-full appearance-none rounded-md border",
        "border-gray-300 placeholder-gray-400 shadow-sm",
        "focus:border-ps-500 focus:outline-none focus:ring-ps-500",
        styles.size[size]
      )}
      {...props}
    />
  );
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["email", "password", "text", "number"]).isRequired,
  size: PropTypes.oneOf(["md"]).isRequired,
};

Input.defaultProps = {
  type: "text",
  size: "md",
};

export default Input;
