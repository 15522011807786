import { StrictMode, Suspense } from "react";
import * as ReactDOM from "react-dom/client";
import assert from "assert";

import { QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AuthProvider from "./components/AuthProvider/AuthProvider";
import Spinner from "./components/Spinner";
import routes from "./routes";
import queryClient from "./utils/queryClient";

import "./i18n";

import "./styles/index.css";

const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
assert(REACT_APP_PUBLIC_URL, "No public URL provided to set as basename");

const domContainer = document.getElementById("root");
const root = ReactDOM.createRoot(domContainer);

const router = createBrowserRouter(routes);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Suspense fallback={<Spinner isFull />}>
          <RouterProvider router={router} />
        </Suspense>
      </AuthProvider>
    </QueryClientProvider>
  </StrictMode>
);
