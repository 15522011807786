import _ from "lodash";
import PropTypes from "prop-types";

import { Fragment, useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { useMutation } from "@tanstack/react-query";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

import Button from "../../components/core/Button/Button";
import {
  setAccountChartBuildings,
  getAccountChartBuildings,
} from "../../loaders/accountCharts";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BuildingsSelector({ accountChart }) {
  const { accountChartId, name } = accountChart;

  const { t } = useTranslation(["dashboard"]);

  const [selectedBuildings, setSelectedBuildings] = useState([]);
  const [availableBuildings, setAvailableBuildings] = useState([]);

  const { data, isLoading, refetch } = useQuery(
    {
      queryKey: ["accountChartBuildings"],
      queryFn: () => getAccountChartBuildings(accountChartId),
    },
    [accountChartId]
  );

  useEffect(() => {
    refetch();
  }, [accountChartId]);

  useEffect(() => {
    if (!isLoading) {
      for (const selectedBuilding of data.buildings) {
        selectedBuilding.currentlySelected = true;
      }
      setSelectedBuildings(data.buildings);

      for (const availableBuilding of data.availableBuildings) {
        availableBuilding.currentlySelected = false;
      }
      setAvailableBuildings(data.availableBuildings);
    }
  }, [accountChartId, data]);

  const mutation = useMutation({
    mutationFn: setAccountChartBuildings,
  });

  const onSubmit = useCallback(async (event) => {
    event.preventDefault();

    mutation.mutate({ accountChartId, name, buildings: selectedBuildings });
  });

  if (isLoading) {
    return null;
  }

  const onChange = (data) => {
    let newSelectedBuildings = [];
    let newAvailableBuildings = [];
    if (data.currentlySelected) {
      // Building should be moved from selected to available
      data.currentlySelected = false;

      const filteredSelectedBuildings = selectedBuildings.filter(
        (selectedBuilding) => selectedBuilding.buildingId !== data.buildingId
      );

      newSelectedBuildings = filteredSelectedBuildings;
      newAvailableBuildings = [data, ...availableBuildings];
    } else {
      // Building should be moved from available to selected
      data.currentlySelected = true;

      const filteredAvailableBuildings = availableBuildings.filter(
        (availableBuilding) => availableBuilding.buildingId !== data.buildingId
      );

      newSelectedBuildings = [data, ...selectedBuildings];
      newAvailableBuildings = filteredAvailableBuildings;
    }

    setSelectedBuildings(newSelectedBuildings);
    setAvailableBuildings(newAvailableBuildings);
  };

  const buildings = _.sortBy(
    [...selectedBuildings, ...availableBuildings],
    ["buildingReference"]
  );

  let displayValue = t("accountCharts.buildings.select");
  if (selectedBuildings.length) {
    displayValue = selectedBuildings
      .map((building) => building.buildingReference)
      .join(", ");
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <Listbox as="div" value={buildings} onChange={onChange}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium text-gray-700">
                {t("accountCharts.buildings.label")}
              </Listbox.Label>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="block truncate">{displayValue}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {buildings.map((building) => (
                      <Listbox.Option
                        key={building.buildingId}
                        value={building}
                        className={({ active }) =>
                          classNames(
                            active
                              ? "text-white bg-indigo-600"
                              : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                      >
                        {({ active, selected }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {building.buildingReference}
                            </span>
                            {building.currentlySelected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                  fill="green"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        <Button type="submit">{"Submit"}</Button>
      </form>
    </>
  );
}

BuildingsSelector.propTypes = {
  accountChart: PropTypes.object.isRequired,
};
