import { forwardRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import useResizeObserver from "use-resize-observer";

import Page from "../Page/Page";
import AgGridReact from "../Grid/Grid";

import {
  BuildingReference,
  ApplicableDate,
  AccountNumber,
  Budget,
  Underwriting,
} from "./columns/";

const Grid = forwardRef(({ internalBenchmarks }, gridRef) => {
  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        });
      }
    },
  });

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      });
    }
  }, []);

  const gridOptions = useMemo(() => ({
    columnDefs: [
      BuildingReference,
      ApplicableDate,
      AccountNumber,
      Budget,
      Underwriting,
    ],
    rowSelection: "multiple",
  }));

  return (
    <>
      <Page.Section.Content className="ag-theme-alpine">
        <AgGridReact
          domLayout="autoHeight"
          onFirstDataRendered={onFirstDataRendered}
          modules={[ClientSideRowModelModule, RichSelectModule]}
          gridOptions={gridOptions}
          rowData={internalBenchmarks}
          ref={gridRef}
        />
      </Page.Section.Content>
    </>
  );
});

Grid.propTypes = {
  internalBenchmarks: PropTypes.array.isRequired,
};

Grid.displayName = "Grid";

export default Grid;
