import {
  categoryValueSetter,
  categoryValueGetter,
  categoryCellEditorParams,
  getColumnCategories,
} from "./helpers";

export default (categories) => {
  const field = "type";

  const columnCategories = getColumnCategories(categories, field);
  const nestingLevel = columnCategories[0].nestingLevel;

  return {
    headerName: "Type",
    cellEditor: "agRichSelectCellEditor",
    resizable: true,
    cellStyle: {
      color: "green",
    },
    cellEditorPopup: true,
    valueGetter: categoryValueGetter(field),
    valueSetter: categoryValueSetter(
      field,
      nestingLevel,
      columnCategories,
      categories
    ),
    cellEditorParams: categoryCellEditorParams(field, columnCategories),
  };
};
