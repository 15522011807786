import { useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { createRecord } from "../loaders/records";
import toast from "react-hot-toast";

import Page from "../components/Page/Page";
import Card from "../components/Card/Card";
import InputField from "../components/core/Input/InputField";
import Input from "../components/core/Input/Input";
import Label from "../components/core/Input/Label";
import Button from "../components/core/Button/Button";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "../components/core/Listbox";
import { useNavigate } from "react-router-dom";
import BuildingSelector from "../components/BuildingSelector/BuildingSelector";

const fields = [
  {
    id: "text",
    type: "text",
  },
  {
    id: "textLocale",
    type: "text",
    defaultValue: "en",
  },
  {
    id: "amount",
    type: "number",
    defaultValue: 0,
  },
  {
    id: "accountNumber",
    type: "number",
    defaultValue: "",
  },
];

const create = async (data) => {
  if (data.accountNumber === "") {
    data.accountNumber = null;
  }

  await createRecord(data);
};

const Create = () => {
  const { t } = useTranslation(["dashboard", "translation"]);
  const [building, setBuilding] = useState(null);

  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: create,
  });

  const [state, setState] = useState(() =>
    fields.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]:
          typeof curr.defaultValue !== "undefined" ? curr.defaultValue : "",
      }),
      {}
    )
  );

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      mutation.mutate(
        {
          ...state,
          buildingId: building.buildingId,
          buildingReference: building.buildingReference,
        },
        {
          onSuccess: () => {
            toast.success(t("createRecord.notification.success"));
            navigate("/setup/records");
          },
          onError: (error) => {
            toast.error(
              t("createRecord.notification.error", {
                error: error.toString(),
              })
            );
          },
        }
      );
    },
    [state]
  );

  return (
    <>
      <Page.Header>{t("createRecord.title")}</Page.Header>
      <Page.Section>
        <Card>
          <form
            onSubmit={onSubmit}
            className="p-6 space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("createRecord.form.title")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("createRecord.form.subtitle")}
                  </p>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div key="building" className="sm:col-span-4">
                    <BuildingSelector
                      selected={building}
                      onChange={setBuilding}
                    />
                  </div>

                  {fields.map((field) => {
                    switch (field.type) {
                      case "option": {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <Listbox
                              value={state[field.id]}
                              onChange={(selected) =>
                                setState((prev) => ({
                                  ...prev,
                                  [field.id]: selected,
                                }))
                              }
                            >
                              {({ open }) => (
                                <>
                                  <Label className="mb-2">
                                    {t(`createRecord.field.${field.id}.title`)}
                                  </Label>
                                  <div className="relative">
                                    <ListboxButton>
                                      {state[field.id]}
                                    </ListboxButton>
                                    <ListboxOptions isOpen={open}>
                                      {field.options.map((option) => (
                                        <ListboxOption
                                          key={option}
                                          value={option}
                                        >
                                          {option}
                                        </ListboxOption>
                                      ))}
                                    </ListboxOptions>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          </div>
                        );
                      }
                      default: {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <InputField id={field.id}>
                              <Label className="mb-2">
                                {t(`createRecord.field.${field.id}.title`)}
                              </Label>
                              <Input
                                type={field.type}
                                name={field.name || field.id}
                                value={state[field.id]}
                                onChange={(event) =>
                                  setState((prev) => ({
                                    ...prev,
                                    [field.id]: event.target.value,
                                  }))
                                }
                              />
                              <p className="mt-2 text-sm text-gray-500">
                                {t(
                                  `createRecord.field.${field.id}.description`
                                )}
                              </p>
                            </InputField>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <Button onClick={() => navigate("/setup/records")}>
                  {t("form.cancel", { ns: "translation" })}
                </Button>
                <Button className="ml-3" type="submit" variant="primary">
                  {t("form.submit", { ns: "translation" })}
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Page.Section>
    </>
  );
};

export default Create;
