import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

import Page from "../components/Page/Page";
import BuildingFilters, {
  useBuildingFilters,
} from "../components/Result/BuildingFilters";
import Heatmap from "../components/Result/Heatmap/Heatmap";
import Overview from "../components/Result/Overview";
import PortfolioSelector from "../components/PortfolioSelector/PortfolioSelector";
import AbsoluteSwitch from "../components/AbsoluteSwitch/AbsoluteSwitch";
import ExcludeIncompleteMonthsSwitch from "../components/ExcludeIncompleteMonthsSwitch/ExcludeIncompleteMonthsSwitch";
import TimeFilterSelector from "../components/TimeFilterSelector/TimeFilterSelector";

import useLocalStorageState from "use-local-storage-state";
import NoiDefinitionSelector from "../components/NoiDefinitionSelector/NoiDefinitionSelector";

const Result = () => {
  const [user] = useLocalStorageState("user");

  const { t } = useTranslation(["dashboard"]);

  const { attributes, selection, onChange, isLoading } = useBuildingFilters();

  const [portfolio, setPortfolio] = useState(null);
  const [timeFilter, setTimeFilter] = useState(null);
  const [noiDefinition, setNoidefinition] = useState(null);
  const [absolute, setAbsolute] = useState(false);
  const [excludeIncompleteMonths, setExcludeIncompleteMonths] = useState(true);

  const currency = user?.currency;

  return (
    <Page>
      <Page.Header subtitle={t("overview.subtitle", { currency })}>
        {t("overview.title")}
      </Page.Header>
      <Page.Section id="noiDefinition">
        <Page.Section.Content>
          <NoiDefinitionSelector
            selected={noiDefinition}
            onChange={setNoidefinition}
          />
        </Page.Section.Content>
      </Page.Section>
      <Page.Section id="portfolio">
        <Page.Section.Content>
          <PortfolioSelector selected={portfolio} onChange={setPortfolio} />
        </Page.Section.Content>
      </Page.Section>
      <Page.Section id="timeFilter">
        <Page.Section.Content>
          <TimeFilterSelector selected={timeFilter} onChange={setTimeFilter} />
        </Page.Section.Content>
      </Page.Section>
      <Page.Section id="absoluteSwitch">
        <Page.Section.Content>
          <AbsoluteSwitch enabled={absolute} onChange={setAbsolute} />
        </Page.Section.Content>
      </Page.Section>
      <Page.Section id="excludeIncompleteMonthsSwitch">
        <Page.Section.Content>
          <ExcludeIncompleteMonthsSwitch
            enabled={excludeIncompleteMonths}
            onChange={setExcludeIncompleteMonths}
          />
        </Page.Section.Content>
      </Page.Section>
      {!isLoading && !!portfolio && !!timeFilter && !!noiDefinition && (
        <div>
          <Overview
            portfolio={portfolio}
            noiDefinition={noiDefinition}
            timeFilter={timeFilter}
            absolute={absolute}
            excludeIncompleteMonths={excludeIncompleteMonths}
          />
          <Fragment>
            <BuildingFilters
              attributes={attributes}
              selection={selection}
              onChange={onChange}
            />
            <Heatmap
              portfolio={portfolio}
              noiDefinition={noiDefinition}
              buildingFilters={selection}
              timeFilter={timeFilter}
              attributes={attributes}
              absolute={absolute}
              excludeIncompleteMonths={excludeIncompleteMonths}
            />
          </Fragment>
        </div>
      )}
    </Page>
  );
};

export default Result;
