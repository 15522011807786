import qs from "query-string";
import httpClient from "../utils/httpClient";

export const getCategoryBuildingDistribution = async (
  categoryId,
  portfolioId,
  noiDefinitionId,
  { year }
) => {
  const query = {};

  if (year) {
    query.year = year;
  }

  const url = qs.stringifyUrl({
    url: `/result/portfolio/${portfolioId}/category/${categoryId}/noiDefinition/${noiDefinitionId}/building_distribution`,
    query,
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getBuildingCategoryDistribution = async (
  buildingId,
  noiDefinitionId,
  { year }
) => {
  const query = {};

  if (year) {
    query.year = year;
  }

  const url = qs.stringifyUrl({
    url: `/result/building/${buildingId}/noiDefinition/${noiDefinitionId}/category_distribution`,
    query,
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getAllTimeFilters = async ({ includeAllOption = false } = {}) => {
  const query = {
    includeAllOption,
  };

  const url = qs.stringifyUrl({
    url: `/result/time_filters`,
    query,
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getTimelines = async (
  portfolioId,
  categoryId,
  noiDefinitionId,
  { annualize = false, absolute = false, byInterval = "quarter" }
) => {
  const url = qs.stringifyUrl({
    url: `/result/portfolio/${portfolioId}/category/${categoryId}/noiDefinition/${noiDefinitionId}/timelines`,
    query: { annualize, absolute, byInterval },
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getAnomaliesStories = async (
  portfolioId,
  categoryId,
  noiDefinitionId,
  { annualize = false, absolute = false, byInterval = "quarter" }
) => {
  const url = qs.stringifyUrl({
    url: `/result/portfolio/${portfolioId}/category/${categoryId}/noiDefinition/${noiDefinitionId}/anomaliesStories`,
    query: { annualize, absolute, byInterval },
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getChildCategoriesTimelines = async (
  buildingId,
  categoryId,
  noiDefinitionId,
  { annualize = false, absolute = false, byInterval = "quarter" }
) => {
  const url = qs.stringifyUrl({
    url: `/result/building/${buildingId}/category/${categoryId}/noiDefinition/${noiDefinitionId}/childCategoriesTimelines`,
    query: { annualize, absolute, byInterval },
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getRampTimelines = async (
  buildingId,
  noiDefinitionId,
  { annualize = false, byInterval = "quarter" } = {}
) => {
  const url = qs.stringifyUrl({
    url: `/result/building/${buildingId}/noiDefinition/${noiDefinitionId}/rampTimelines`,
    query: {
      annualize,
      byInterval,
    },
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getRampStories = async (
  buildingId,
  noiDefinitionId,
  { annualize = false, byInterval = "quarter" }
) => {
  const url = qs.stringifyUrl({
    url: `/result/building/${buildingId}/noiDefinition/${noiDefinitionId}/rampStories`,
    query: {
      annualize,
      byInterval,
    },
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getZeroProof = async () => {
  const response = await httpClient.get(`/result/zeroProof`);

  return response.data;
};

export async function getExpenseOutliers(buildingId, noiDefinitionId) {
  const response = await httpClient.get(
    `/result/building/${buildingId}/noiDefinition/${noiDefinitionId}/expense_outliers`
  );

  return response.data;
}
