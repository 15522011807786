import _ from "lodash";

export const format = (number, { signDisplay } = {}) => {
  if (number == null) {
    return number;
  }

  return new Intl.NumberFormat(navigator.language, {
    signDisplay,
  }).format(number);
};

export const getGridValueFormatter = (field) => {
  return function (params) {
    const value = _.get(params, ["data", field]);

    return format(value);
  };
};
