import _ from "lodash";

import { categoryValueSetter, categoryValueGetter } from "./helpers";

export default (categories) => {
  const field = "businessOrigin";

  const columnCategories = categories.filter(
    (category) => category.nestingLevelColumn === field
  );
  const nestingLevel = columnCategories[0].nestingLevel;

  return {
    headerName: "Origin",
    resizable: true,
    cellEditor: "agRichSelectCellEditor",
    cellStyle: {
      color: "green",
    },
    cellEditorPopup: true,
    valueGetter: categoryValueGetter(field),
    valueSetter: categoryValueSetter(
      field,
      nestingLevel,
      columnCategories,
      categories
    ),
    cellEditorParams: function () {
      const values = columnCategories.map((columnCategory) =>
        _.get(columnCategory, field)
      );

      values.push(null);

      return { values };
    },
  };
};
