export default {
  headerName: "Primary usage",
  field: "primaryUsage",
  cellEditor: "agRichSelectCellEditor",
  width: 150,
  cellEditorPopup: true,
  cellEditorParams: function () {
    return {
      values: ["residential", "office", "commercial"],
    };
  },
};
