import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  Tooltip,
  ReferenceLine,
  Label,
} from "recharts";

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  xAxisDataKey: PropTypes.string.isRequired,
  primaryDataKey: PropTypes.string.isRequired,
  primaryGetFill: PropTypes.func.isRequired,
  referenceLinesData: PropTypes.object,
  BarChartToolTip: PropTypes.elementType,
  onClick: PropTypes.elementType,
  secondaryDataKey: PropTypes.string,
  secondaryGetFill: PropTypes.func,
};

export default function Chart({
  data,
  BarChartToolTip,
  referenceLinesData,
  xAxisDataKey,
  primaryDataKey,
  primaryGetFill,
  secondaryDataKey,
  secondaryGetFill,
  onClick,
}) {
  const { t } = useTranslation(["dashboard"]);

  return (
    <>
      <BarChart
        width={900}
        height={800}
        data={data}
        margin={{ top: 25, right: 100, left: 0, bottom: 150 }}
        onClick={onClick}
        barGap={"0%"}
        barCategoryGap={"20%"}
      >
        <XAxis
          dataKey={xAxisDataKey}
          interval={0}
          fontFamily="sora"
          position="top"
          angle={50}
          textAnchor="right"
        />
        <YAxis />

        {referenceLinesData &&
          Object.keys(referenceLinesData).map((key) => (
            <ReferenceLine
              key={key}
              y={referenceLinesData[key]}
              stroke="grey"
              strokeDasharray="3 3"
            >
              <Label
                value={t("barChart.referenceLine.percentile", {
                  percentile: key,
                })}
                position="right"
              />
            </ReferenceLine>
          ))}
        <Tooltip content={<BarChartToolTip />} />
        <Bar dataKey={primaryDataKey}>
          {data.map((entry, index) => (
            <Cell
              key={index}
              fill={primaryGetFill(data[index])}
              stroke={"grey"}
              strokeWidth={1}
            />
          ))}
        </Bar>
        {secondaryDataKey && (
          <Bar dataKey={secondaryDataKey}>
            {data.map((entry, index) => (
              <Cell
                key={index}
                fill={secondaryGetFill(data[index])}
                stroke={"grey"}
                strokeWidth={1}
              />
            ))}
          </Bar>
        )}
      </BarChart>
    </>
  );
}
