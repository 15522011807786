import PropTypes from "prop-types";

export const Tooltip = ({ children }) => {
  return (
    <div className="bg-white p-3 py-4 rounded-lg text-sm shadow-md ring-1 ring-slate-900/5">
      {children}
    </div>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tooltip;
