import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import useLocalStorageState from "use-local-storage-state";

import AuthContext from "../../contexts/AuthContext";

const AuthProvider = ({ children }) => {
  const [token, setToken] = useLocalStorageState("token");
  const [user, setUser] = useLocalStorageState("user");
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  const login = useCallback(({ token, user }, callback) => {
    setToken(token);
    setUser(user);
    setIsAuthenticated(true);
    callback && callback();
  }, []);

  const logout = useCallback((callback) => {
    setToken(null);
    setUser(null);
    setIsAuthenticated(false);

    callback && callback();
  }, []);

  const value = useMemo(
    () => ({ user, token, isAuthenticated, login, logout }),
    [user, token, isAuthenticated, login, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthProvider;
