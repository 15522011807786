import httpClient from "../utils/httpClient";

export const loginUser = async ({ email, password }) => {
  const response = await httpClient.post(`/user/login`, {
    email,
    password,
  });

  return response.data;
};
