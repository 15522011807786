import { useCallback, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import {
  bulkDelete,
  uploadRecordsFile,
  getRecordsData,
} from "../loaders/records";
import { getZeroProof } from "../loaders/result";

import { getBuildings } from "../loaders/buildings";

import Grid from "../components/Records/Grid";

import Button from "../components/core/Button/Button";
import Page from "../components/Page/Page";
import Spinner from "../components/Spinner";
import UploadData from "../components/UploadData";
import BuildingSelector from "../components/BuildingSelector/BuildingSelector";
import CategorySelector from "../components/CategorySelector/CategorySelector";
import ZeroProof from "../components/ZeroProof/ZeroProof";

export default function Records() {
  const gridRef = useRef(null);
  const { t } = useTranslation(["dashboard"]);

  const [building, setBuilding] = useState(null);
  const [category, setCategory] = useState(null);

  const { data: zeroProof, isLoading: isZeroProofLoading } = useQuery({
    queryKey: ["zeroProof"],
    queryFn: getZeroProof,
  });

  const { data: buildings, isLoading: isBuildingsLoading } = useQuery({
    queryKey: ["buildings"],
    queryFn: getBuildings,
  });

  const {
    data,
    isLoading: isRecordsLoading,
    refetch,
  } = useQuery({
    queryKey: ["buildingRecords", building, category],
    queryFn: () => getRecordsData(building.buildingId, category.categoryId),
    enabled: !!building && !!category,
  });

  const uploadMutation = useMutation({
    mutationFn: uploadRecordsFile,
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells();
      });
    },
  });

  const mutation = useMutation({
    mutationFn: bulkDelete,
    onSuccess: () => {
      return refetch().then(() => {
        gridRef.current.api.refreshCells();
      });
    },
  });

  const onUpload = useCallback((event) => {
    const files = Array.from(event.target.files);

    uploadMutation.mutate(files[0]);
  });

  const onDownloadAll = useCallback(() => {
    gridRef.current.api.exportDataAsExcel();
  });

  const onDeleteSelection = useCallback(() => {
    const selection = gridRef.current.api.getSelectedRows();

    mutation.mutate(selection.map((item) => item.recordId));
  }, []);

  return (
    <>
      <Page.Header
        actions={
          <>
            <Button variant="negative" onClick={onDeleteSelection}>
              {t("records.deleteSelection")}
            </Button>
            <Button onClick={onDownloadAll} className="ml-2">
              {t("records.downloadAll")}
            </Button>
            <UploadData onUpload={onUpload} />
            <Button as={Link} to="/setup/records/new" className="ml-2">
              {t("records.createRecord")}
            </Button>
          </>
        }
      >
        {t("records.title")}
      </Page.Header>

      <Page.Section id="zeroProof">
        {isZeroProofLoading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <ZeroProof data={zeroProof} ref={gridRef} />
        )}
      </Page.Section>

      <Page.Section id="buildingSelector">
        <Page.Section.Content className="ag-theme-alpine">
          <div key="building">
            <BuildingSelector
              selected={building}
              onChange={setBuilding}
              allowMissing={true}
            />
          </div>
        </Page.Section.Content>
      </Page.Section>

      <Page.Section id="categorySelector">
        <Page.Section.Content className="ag-theme-alpine">
          <div key="category">
            <CategorySelector
              selected={category}
              onChange={setCategory}
              topCategoryStandard={"root"}
              includeTopCategory={false}
              includeNull={false}
            />
          </div>
          <div className="mt-2">
            {t("records.categorySelector.childCategoriesIncluded")}
          </div>
        </Page.Section.Content>
      </Page.Section>

      <Page.Section id="grid">
        {isRecordsLoading || isBuildingsLoading ? (
          <div className="flex justify-center">
            <Spinner />
          </div>
        ) : (
          <Grid data={data} buildings={buildings} ref={gridRef} />
        )}
      </Page.Section>
    </>
  );
}
