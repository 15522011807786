import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "../../components/core/Listbox";

export default function ByIntervalSelector({ selected, onChange }) {
  const { t } = useTranslation(["dashboard"]);

  const byIntervals = ["quarter", "month"];

  return (
    <Listbox value={selected} onChange={(byInterval) => onChange(byInterval)}>
      {({ open }) => (
        <>
          <ListboxLabel>{t("byIntervalSelector.label")}</ListboxLabel>
          <div className="relative mt-1">
            <ListboxButton>
              {t(`byIntervalSelector.interval.${selected}`)}
            </ListboxButton>
            <ListboxOptions isOpen={open}>
              {byIntervals.map((byInterval) => (
                <ListboxOption key={byInterval} value={byInterval}>
                  {t(`byIntervalSelector.interval.${byInterval}`)}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </>
      )}
    </Listbox>
  );
}

ByIntervalSelector.propTypes = {
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
