import { Outlet } from "react-router-dom";
import Page from "../components/Page/Page";

const Setup = () => {
  return (
    <Page>
      <Outlet />
    </Page>
  );
};

export default Setup;
