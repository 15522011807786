export default {
  headerName: "Concerning",
  valueGetter: ({ data }) => {
    const { category } = data;

    if (!category) {
      return;
    }

    return category.concerning;
  },
  cellStyle: {
    color: "green",
  },
};
