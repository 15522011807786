import PropTypes from "prop-types";
import classnames from "classnames";

const Card = ({ as: Component, className, ...props }) => (
  <Component
    className={classnames(
      "overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:py-6",
      className
    )}
    {...props}
  />
);

Card.propTypes = {
  as: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Card.defaultProps = {
  as: "div",
};

export default Card;
