import { Outlet } from "react-router-dom";
import Toaster from "../ToastProvider";

import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

const App = () => {
  return (
    <div className="relative flex min-h-full flex-col">
      <Navbar />
      <div
        className="w-full flex-grow flex fixed top-16"
        style={{
          height: "calc(100vh - 4rem)",
        }}
      >
        <Sidebar />
        <Outlet />
      </div>
      <Toaster />
    </div>
  );
};

export default App;
