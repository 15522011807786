import { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { getAllTimeFilters } from "../../loaders/result";

import {
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "../core/Listbox";

const useTimeFilters = (options) => {
  const { data: timeFilters, isLoading } = useQuery({
    queryKey: ["timeFilters"],
    queryFn: () => getAllTimeFilters({ includeAllOption: false }),
    staleTime: 2000,
    ...options,
  });

  return {
    timeFilters,
    isLoading,
  };
};

const PERIOD_TYPES = {
  year: "Full year",
  quarter: "Quarters",
  month: "Months",
};

const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getTranslatedPeriodTypes(t) {
  return Object.fromEntries(
    Object.entries(PERIOD_TYPES).map(([key]) => [
      key,
      t(`timeFilters.period.${key}`),
    ])
  );
}

function getTranslatedPeriods(periodType, t) {
  switch (periodType) {
    case "quarter":
      return QUARTERS.map((quarter) =>
        t(`timeFilters.quarter.${quarter.toLowerCase()}`)
      );
    case "month":
      return MONTHS.map((month) =>
        t(`timeFilters.month.${month.toLowerCase()}`)
      );
    default:
      return [];
  }
}

function getMinAndMaxPeriods(periodType) {
  switch (periodType) {
    case "quarter":
      return [0, 3];
    case "month":
      return [0, 11];
    default:
      return [null, null];
  }
}

// Return min and max months in range 1-12
function getMinAndMaxMonths(periodType, startPeriod, endPeriod) {
  switch (periodType) {
    case "quarter":
      return [startPeriod * 3 + 1, (endPeriod + 1) * 3];
    case "month":
      return [startPeriod + 1, endPeriod + 1];
    default:
      return [];
  }
}

export default function TimeFilterSelector({ selected, onChange }) {
  const { t } = useTranslation(["dashboard"]);

  const initialYear = selected ? selected.name : null;

  const [year, setYear] = useState(initialYear);
  const [periodType, setPeriodType] = useState("year");
  const [startPeriod, setStartPeriod] = useState(null);
  const [endPeriod, setEndPeriod] = useState(null);

  const periodTypes = useMemo(() => getTranslatedPeriodTypes(t), []);
  const periods = useMemo(
    () => getTranslatedPeriods(periodType, t),
    [periodType]
  );

  // Reset start/end periods to span full year if the period type changes
  useEffect(() => {
    const [start, end] = getMinAndMaxPeriods(periodType);
    setStartPeriod(start);
    setEndPeriod(end);
  }, [periodType]);

  // Reset end period if before start period, since it is invalid
  useEffect(() => {
    if (startPeriod !== null && endPeriod !== null && endPeriod < startPeriod) {
      setEndPeriod(startPeriod);
    }
  }, [startPeriod, endPeriod]);

  // Call onChange when selection changes
  useEffect(() => {
    if (!year) {
      return;
    }

    const [minMonth, maxMonth] = getMinAndMaxMonths(
      periodType,
      startPeriod,
      endPeriod
    );
    const timeFilter = {
      year,
      minMonth,
      maxMonth,
    };

    onChange(timeFilter);
  }, [year, periodType, startPeriod, endPeriod]);

  // Download time filters
  const { timeFilters, isLoading } = useTimeFilters({
    onSuccess: (data) => {
      if (!selected) {
        const { name } = data[0];
        setYear(name);
      }
    },
  });

  if (isLoading || !year) {
    return null;
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-5">
        <Listbox value={year} onChange={setYear}>
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <ListboxLabel>
                  {t("timeFilters.selector.yearLabel")}
                </ListboxLabel>
                <ListboxButton>{year}</ListboxButton>
                <ListboxOptions isOpen={open}>
                  {timeFilters.map(({ name }) => (
                    <ListboxOption key={name} value={name}>
                      {name}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </>
          )}
        </Listbox>
        <Listbox value={periodType} onChange={setPeriodType}>
          {({ open }) => (
            <>
              <div className="relative mt-1">
                <ListboxLabel>
                  {t("timeFilters.selector.periodLabel")}
                </ListboxLabel>
                <ListboxButton>{periodTypes[periodType]}</ListboxButton>
                <ListboxOptions isOpen={open}>
                  {Object.entries(periodTypes).map(([key, value]) => (
                    <ListboxOption key={key} value={key}>
                      {value}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </>
          )}
        </Listbox>
        <Listbox value={startPeriod} onChange={setStartPeriod}>
          {({ open }) => (
            <>
              <div
                className={classNames("relative", "mt-1", {
                  hidden: periods.length === 0,
                })}
              >
                <ListboxLabel>
                  {t("timeFilters.selector.startPeriodLabel")}
                </ListboxLabel>
                <ListboxButton>
                  {startPeriod !== null ? periods[startPeriod] : "…"}
                </ListboxButton>
                <ListboxOptions isOpen={open}>
                  {periods.map((period, index) => (
                    <ListboxOption key={period} value={index}>
                      {period}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </>
          )}
        </Listbox>
        <Listbox value={endPeriod} onChange={setEndPeriod}>
          {({ open }) => (
            <>
              <div
                className={classNames("relative", "mt-1", {
                  hidden: periods.length === 0,
                })}
              >
                <ListboxLabel>
                  {t("timeFilters.selector.endPeriodLabel")}
                </ListboxLabel>
                <ListboxButton>
                  {startPeriod !== null ? periods[endPeriod] : "…"}
                </ListboxButton>
                <ListboxOptions isOpen={open}>
                  {periods.map((period, index) => (
                    <ListboxOption
                      key={period}
                      value={index}
                      disabled={periods.indexOf(period) < startPeriod}
                    >
                      {period}
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </>
          )}
        </Listbox>
      </div>
    </>
  );
}

TimeFilterSelector.propTypes = {
  selected: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};
