import qs from "query-string";
import httpClient from "../utils/httpClient";

export const getCategories = async ({
  topCategoryStandard = "root",
  includeTopCategory = true,
  includeNull = false,
} = {}) => {
  const url = qs.stringifyUrl({
    url: "/category/all",
    query: {
      topCategoryStandard,
      includeTopCategory,
      includeNull,
    },
  });

  const response = await httpClient.get(url);

  return response.data;
};
