import { forwardRef, useCallback, useMemo } from "react";
import PropTypes from "prop-types";

import { updateAccount } from "../../loaders/accounts";

import Page from "../Page/Page";

import AgGridReact from "../Grid/Grid";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { RichSelectModule } from "@ag-grid-enterprise/rich-select";
import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";
import useResizeObserver from "use-resize-observer";

import {
  Name,
  NoiDefinitions,
  Number,
  Kind,
  BusinessOrigin,
  Type,
  Entry,
  Concerning,
  Specifics,
} from "./columns";

const Grid = forwardRef(({ accounts, noiDefinitions }, gridRef) => {
  useResizeObserver({
    ref: document.body,
    onResize: () => {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit({
          defaultMinWidth: 160,
        });
      }
    },
  });

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 160,
      });
    }
  }, []);

  const gridOptions = useMemo(
    () => ({
      defaultColDef: {
        editable: true,
        suppressMovable: true,
      },
      columnDefs: [
        Name,
        NoiDefinitions(noiDefinitions),
        Number,
        Kind,
        BusinessOrigin,
        Type,
        Entry,
        Concerning,
        Specifics,
      ],
      onCellValueChanged: async function ({ data }) {
        const { accountId, categoryId } = data;

        const noiDefinitionIds = data.noiDefinitions.map(
          (noiDefinition) => noiDefinition.noiDefinitionId
        );

        await updateAccount({ accountId, categoryId, noiDefinitionIds });
      },
      rowSelection: "multiple",
    }),
    [accounts, noiDefinitions]
  );

  return (
    <Page.Section.Content className="ag-theme-alpine">
      <AgGridReact
        domLayout="autoHeight"
        gridOptions={gridOptions}
        modules={[
          ClientSideRowModelModule,
          RichSelectModule,
          ExcelExportModule,
        ]}
        onFirstDataRendered={onFirstDataRendered}
        ref={gridRef}
        rowClass="c-grid-row"
        rowData={accounts}
      />
    </Page.Section.Content>
  );
});

Grid.propTypes = {
  accounts: PropTypes.array,
  noiDefinitions: PropTypes.array,
};

Grid.displayName = "Grid";

export default Grid;
