import PropTypes from "prop-types";

import logoURL from "../../assets/img/logo_dark.svg";

import Menu from "./Menu";

const CompanyLogo = ({ name }) => {
  return (
    <div className="flex flex-shrink-0 items-center text-white">
      <img className="block h-8 w-auto lg:hidden" src={logoURL} alt={name} />
      <img className="hidden h-8 w-auto lg:block" src={logoURL} alt={name} />
      <span className="ml-2 font-bold">{name}</span>
    </div>
  );
};

CompanyLogo.propTypes = {
  name: PropTypes.string.isRequired,
};

const Navbar = ({ companyName }) => {
  return (
    <nav className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-green shadow-md">
      <div className="mx-auto flex-1 px-4 sm:px-6">
        <div className="relative flex h-16 justify-between">
          <div className="flex flex-1 items-center justify-center sm:justify-start">
            <CompanyLogo name={companyName} />
          </div>

          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            <Menu />
          </div>
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = {
  companyName: PropTypes.string.isRequired,
};

Navbar.defaultProps = {
  companyName: "Propsponge",
};

export default Navbar;
