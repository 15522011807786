import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Switch, SwitchLabel, SwitchGroup } from "../core/Switch";

export default function ExcludeIncompleteMonthsSwitch({ enabled, onChange }) {
  const { t } = useTranslation(["dashboard"]);

  return (
    <SwitchGroup as="div" className="flex justify-left">
      <Switch enabled={enabled} onChange={onChange}></Switch>
      <SwitchLabel as="span" className="ml-3 text-sm">
        {t("excludeIncompleteMonths.label")}
      </SwitchLabel>
    </SwitchGroup>
  );
}

ExcludeIncompleteMonthsSwitch.propTypes = {
  enabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
