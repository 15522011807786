import { Fragment, useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { createAccountChart } from "../loaders/accountCharts";
import toast from "react-hot-toast";

import Page from "../components/Page/Page";
import Card from "../components/Card/Card";
import InputField from "../components/core/Input/InputField";
import Input from "../components/core/Input/Input";
import Label from "../components/core/Input/Label";
import Button from "../components/core/Button/Button";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "../components/core/Listbox";
import { useNavigate } from "react-router-dom";

const fields = [
  {
    id: "name",
    type: "text",
  },
];

const AssignData = ({ onAssign }) => {
  const { t } = useTranslation("dashboard");
  const inputRef = useRef(null);

  const onClick = useCallback((event) => {
    event.preventDefault();
    inputRef.current && inputRef.current.click();
  });

  return (
    <Fragment>
      <input
        ref={inputRef}
        onChange={onAssign}
        id="uploadInput"
        type="file"
        className="sr-only"
        accept=".xlsx, .xls, .csv"
        tabIndex={-1}
      />
      <Button onClick={onClick} className="ml-2">
        {t("createAccountChart.assign.label")}
      </Button>
    </Fragment>
  );
};

AssignData.propTypes = {
  onAssign: PropTypes.func.isRequired,
};

const CreateAccountChart = () => {
  const { t } = useTranslation(["dashboard", "translation"]);

  const navigate = useNavigate();
  const mutation = useMutation({
    mutationFn: createAccountChart,
  });

  const [state, setState] = useState(() =>
    fields.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr.defaultValue || "",
      }),
      {}
    )
  );

  const onAssign = useCallback((event) => {
    const file = Array.from(event.target.files)[0];

    setState({
      ...state,
      file,
    });
  });

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      mutation.mutate(state, {
        onSuccess: () => {
          toast.success(t("createAccountChart.notification.success"));
          navigate("/setup/account_charts");
        },
        onError: (error) => {
          toast.error(
            t("createAccountChart.notification.error", {
              error: error.toString(),
            })
          );
        },
      });
    },
    [state]
  );

  return (
    <>
      <Page.Header>{t("createAccountChart.title")}</Page.Header>
      <Page.Section>
        <Card>
          <form
            onSubmit={onSubmit}
            className="p-6 space-y-8 divide-y divide-gray-200"
          >
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div>
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {t("createAccountChart.form.title")}
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    {t("createAccountChart.form.subtitle")}
                  </p>
                </div>

                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  {fields.map((field) => {
                    switch (field.type) {
                      case "option": {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <Listbox
                              value={state[field.id]}
                              onChange={(selected) =>
                                setState((prev) => ({
                                  ...prev,
                                  [field.id]: selected,
                                }))
                              }
                            >
                              {({ open }) => (
                                <>
                                  <Label className="mb-2">
                                    {t(
                                      `createAccountChart.field.${field.id}.title`
                                    )}
                                  </Label>
                                  <div className="relative">
                                    <ListboxButton>
                                      {state[field.id]}
                                    </ListboxButton>
                                    <ListboxOptions isOpen={open}>
                                      {field.options.map((option) => (
                                        <ListboxOption
                                          key={option}
                                          value={option}
                                        >
                                          {option}
                                        </ListboxOption>
                                      ))}
                                    </ListboxOptions>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          </div>
                        );
                      }
                      default: {
                        return (
                          <div key={field.id} className="sm:col-span-4">
                            <InputField id={field.id}>
                              <Label className="mb-2">
                                {t(
                                  `createAccountChart.field.${field.id}.title`
                                )}
                              </Label>
                              <Input
                                type={field.type}
                                name={field.name || field.id}
                                value={state[field.id]}
                                onChange={(event) =>
                                  setState((prev) => ({
                                    ...prev,
                                    [field.id]: event.target.value,
                                  }))
                                }
                              />
                              <p className="mt-2 text-sm text-gray-500">
                                {t(
                                  `createAccountChart.field.${field.id}.description`
                                )}
                              </p>
                            </InputField>
                          </div>
                        );
                      }
                    }
                  })}
                </div>
              </div>
            </div>

            <AssignData onAssign={onAssign} />
            <div className="pt-5">
              <div className="flex justify-end">
                <Button onClick={() => navigate("/setup/account_charts")}>
                  {t("form.cancel", { ns: "translation" })}
                </Button>
                <Button
                  className="ml-3"
                  type="submit"
                  variant="primary"
                  disabled={!state.file}
                >
                  {t("form.submit", { ns: "translation" })}
                </Button>
              </div>
            </div>
          </form>
        </Card>
      </Page.Section>
    </>
  );
};

export default CreateAccountChart;
