import classNames from "classnames";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import * as Typography from "../core/Typography";

export const Header = ({ actions, subtitle, children }) => {
  return (
    <header>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex">
        <div className="flex-1">
          <Typography.H1>{children}</Typography.H1>
          {subtitle && (
            <p className="mt-2 text-lg font-light text-ps-500">{subtitle}</p>
          )}
        </div>
        {actions && <div>{actions}</div>}
      </div>
    </header>
  );
};

Header.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
};

export const Section = (props) => (
  <section
    className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-16"
    {...props}
  />
);

export const SectionTitle = (props) => <Typography.H3 {...props} />;
Section.Title = SectionTitle;

export const Content = forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={classNames("my-6", className)} {...props} />
));

Content.displayName = "Content";

Content.propTypes = {
  className: PropTypes.string,
};

Section.Content = Content;

const Page = ({ children }) => {
  return (
    <main className="min-w-0 flex-1 py-10 bg-ps-50 overflow-y-auto">
      {children}
    </main>
  );
};

Page.Header = Header;
Page.Section = Section;

Page.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Page;
