import qs from "query-string";
import httpClient from "../utils/httpClient";

export const getOverview = async (
  portfolioId,
  noiDefinitionId,
  { year, minMonth, maxMonth },
  absolute,
  excludeIncompleteMonths
) => {
  const query = {};

  if (year) {
    query.year = year;

    if (minMonth && maxMonth) {
      query.minMonth = minMonth;
      query.maxMonth = maxMonth;
    }
  }

  if (absolute) {
    query.absolute = absolute;
  }

  if (excludeIncompleteMonths) {
    query.excludeIncompleteMonths = excludeIncompleteMonths;
  }

  const url = qs.stringifyUrl({
    url: `/result/portfolio/${portfolioId}/noiDefinition/${noiDefinitionId}/overview`,
    query,
  });

  const response = await httpClient.get(url);

  return response.data;
};
