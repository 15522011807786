import qs from "query-string";
import httpClient from "../utils/httpClient";

export const getHeatmap = async (
  portfolioId,
  noiDefinitionId,
  { primaryUsage, size, constructionYear, floors, units } = {},
  { year, minMonth, maxMonth } = {},
  absolute,
  excludeIncompleteMonths
) => {
  const query = {
    buildingPrimaryUsage: primaryUsage.id,
    buildingSize: size.id,
    buildingConstructionYear: constructionYear.id,
    buildingFloors: floors.id,
    buildingUnits: units.id,
  };

  if (year) {
    query.year = year;

    if (minMonth && maxMonth) {
      query.minMonth = minMonth;
      query.maxMonth = maxMonth;
    }
  }

  if (absolute != null) {
    query.absolute = absolute;
  }

  if (excludeIncompleteMonths != null) {
    query.excludeIncompleteMonths = excludeIncompleteMonths;
  }

  const url = qs.stringifyUrl({
    url: `/result/portfolio/${portfolioId}/noiDefinition/${noiDefinitionId}/heatmap`,
    query,
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getChildCategoriesRows = async (
  buildingId,
  categoryId,
  noiDefinitionId,
  { year, minMonth, maxMonth } = {},
  absolute,
  excludeIncompleteMonths
) => {
  const query = {};

  if (year) {
    query.year = year;

    if (minMonth && maxMonth) {
      query.minMonth = minMonth;
      query.maxMonth = maxMonth;
    }
  }

  if (absolute != null) {
    query.absolute = absolute;
  }

  if (excludeIncompleteMonths != null) {
    query.excludeIncompleteMonths = excludeIncompleteMonths;
  }

  const url = qs.stringifyUrl({
    url: `/result/heatmap/building/${buildingId}/category/${categoryId}/noiDefinition/${noiDefinitionId}/childCategoriesRows`,
    query,
  });

  const response = await httpClient.get(url);

  return response.data;
};

export const getChildRecordsRows = async (
  buildingId,
  categoryId,
  noiDefinitionId,
  { year, minMonth, maxMonth } = {},
  absolute,
  excludeIncompleteMonths
) => {
  const query = {};

  if (year) {
    query.year = year;

    if (minMonth && maxMonth) {
      query.minMonth = minMonth;
      query.maxMonth = maxMonth;
    }
  }

  if (absolute != null) {
    query.absolute = absolute;
  }

  if (excludeIncompleteMonths != null) {
    query.excludeIncompleteMonths = excludeIncompleteMonths;
  }

  const url = qs.stringifyUrl({
    url: `/result/heatmap/building/${buildingId}/category/${categoryId}/noiDefinition/${noiDefinitionId}/childRecordsRows`,
    query,
  });

  const response = await httpClient.get(url);

  return response.data;
};
