import PropTypes from "prop-types";
import classnames from "classnames";

import { useFieldContext } from "./InputField";

const Label = ({ className, children, htmlFor }) => {
  const context = useFieldContext();

  return (
    <label
      className={classnames(
        "block text-sm font-medium text-gray-900",
        className
      )}
      htmlFor={htmlFor || context.id}
    >
      {children}
    </label>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Label;
