import PropTypes from "prop-types";
import classnames from "classnames";

export const Header = ({ as: Component, ...props }) => <Component {...props} />;

Header.propTypes = {
  as: PropTypes.string.isRequired,
};

Header.defaultProps = {
  as: "h1",
};

export const H1 = ({ className, ...props }) => (
  <Header
    as="h1"
    className={classnames(
      "text-3xl font-bold leading-tight tracking-tight text-gray-900",
      className
    )}
    {...props}
  />
);

H1.propTypes = {
  className: PropTypes.string,
};

export const H2 = ({ className, ...props }) => (
  <Header
    as="h2"
    className={classnames(
      "text-3xl font-bold tracking-tight text-gray-900",
      className
    )}
    {...props}
  />
);

export const H3 = ({ className, ...props }) => (
  <Header
    as="h3"
    className={classnames("text-2xl leading-7", className)}
    {...props}
  />
);

H2.propTypes = H1.propTypes;
H3.propTypes = H1.propTypes;
